import React from 'react';
import { Link } from 'react-router-dom';

const NavMenu = ({ isVisible, toggleMenu }) => {
    return (
        <div 
            className={`fixed top-0 left-0 h-full w-80 bg-white shadow-2xl transform transition-transform duration-300 ease-in-out z-50 ${
                isVisible ? 'translate-x-0' : '-translate-x-full'
            }`}
            style={{ display: isVisible ? 'block' : 'none' }}
        >
            <nav className="h-full overflow-y-auto">
                {/* Logo/Header Section */}
                <div className="sticky top-0 bg-white/90 backdrop-blur-sm border-b border-gray-200 px-6 py-8">
                    <h2 className="text-2xl font-bold text-gray-800">Menu</h2>
                </div>

                {/* Navigation Links */}
                <ul className="pt-2 pb-20">
                    {[
                        { to: "/", label: "Home", icon: "🏠" },
                        { to: "/exhibition", label: "Mint and Discover", icon: "🔍" },
                        { to: "/inscribe", label: "Inscribe", icon: "✍️" },
                        { to: "/p5-magic", label: "P5.js Magic", icon: "🎨" },
                        { to: "/HTML-Compress-Magic", label: "HTML Compress", icon: "📝" },
                        { to: "/sound-magic", label: "Sound Magic", icon: "🎵" },
                        { to: "/Image-Compress-Magic", label: "Image Compress", icon: "🖼️" },
                        { to: "/inscribe-pad", label: "Inscribe Pad", icon: "📝" },
                        { to: "/Video-Compress-Magic", label: "Video Compress", icon: "🎥" },
                        { to: "/mint", label: "PMT", icon: "💎" },
                        { to: "/exhibition", label: "Exhibition", icon: "🎭" },
                        { to: "/gallery", label: "Gallery", icon: "🖼️" },
                        { to: "/balance", label: "Balance", icon: "💰" },
                        { to: "/transaction-history", label: "Transaction History", icon: "📊" },
                        { to: "/what-are-people-buying?", label: "Trending", icon: "📈" },
                        { to: "/orders", label: "Order Status", icon: "📦" },
                       
                    ].map((item, index) => (
                        <li 
                            key={index}
                            className="border-b border-gray-100 last:border-b-0"
                        >
                            <Link 
                                to={item.to} 
                                onClick={toggleMenu}
                                className="flex items-center px-6 py-4 text-gray-700 hover:bg-purple-50 hover:text-purple-700 transition-all duration-200 group"
                            >
                                <span className="mr-3 text-xl">{item.icon}</span>
                                <span className="text-base font-medium group-hover:translate-x-1 transition-transform duration-200">
                                    {item.label}
                                </span>
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>

            {/* Overlay for mobile */}
            {isVisible && (
                <div 
                    className="fixed inset-0 bg-black/20 backdrop-blur-sm z-40 lg:hidden"
                    onClick={toggleMenu}
                    style={{ marginLeft: '20rem' }}
                />
            )}
        </div>
    );
};

export default NavMenu;