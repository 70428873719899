import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import './HTMLCompressMagic.css';
import ConfirmPurchaseModal from './ConfirmPurchaseModal';
import * as fflate from 'fflate';

const BACKEND_URL = 'https://inscription-service-production.up.railway.app';

const SavingsModal = ({ isOpen, onClose, savings }) => {
    if (!isOpen) return null;
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-xl p-8 max-w-md w-full mx-4 shadow-2xl">
                <h4 className="text-2xl font-bold mb-4 text-gray-800">Compression Results</h4>
                <div className="mb-6">
                    <div className="text-4xl font-bold text-green-500 mb-2">{savings}%</div>
                    <p className="text-gray-600">File size reduction achieved</p>
                </div>
                <button 
                    onClick={onClose}
                    className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded-lg transition duration-200"
                >
                    Close
                </button>
            </div>
        </div>
    );
};

const HTMLCompressMagic = () => {
    const [inputCode, setInputCode] = useState('');
    const [finalHtml, setFinalHtml] = useState('');
    const [orderDetails, setOrderDetails] = useState({
        totalAmount: 0,
        totalAmountBTC: '',
        payAddress: '',
        receiverAddress: '',
        devAddress: '',
        devFee: 0,
        feeRate: 10,
    });
    const [recommendedFees, setRecommendedFees] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [savingsModalOpen, setSavingsModalOpen] = useState(false);
    const [savings, setSavings] = useState(0);
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState(null);
    const [processingMessage, setProcessingMessage] = useState('');
    const iframeRef = useRef(null);
    const [iframeKey, setIframeKey] = useState(0);

    useEffect(() => {
        const fetchRecommendedFees = async () => {
            try {
                const response = await axios.get('https://mempool.space/api/v1/fees/recommended');
                setRecommendedFees(response.data);
            } catch (error) {
                console.error('Error fetching recommended fees:', error);
                setError('Failed to fetch recommended fees');
            }
        };
        fetchRecommendedFees();
    }, []);

    const displaySketch = useCallback(() => {
        if (!inputCode.trim()) return;

        const viewerHtml = inputCode.replace(/['"]\/content\/(.*?)['"]/g, '"https://ordinals.com/content/$1"');
        const blob = new Blob([viewerHtml], { type: 'text/html' });
        const url = URL.createObjectURL(blob);

        if (iframeRef.current) {
            iframeRef.current.src = url;
        }
    }, [inputCode]);

    useEffect(() => {
        displaySketch();
    }, [iframeKey, displaySketch]);

    const handleCodeChange = (event) => {
        setInputCode(event.target.value);
        setIframeKey((prevKey) => prevKey + 1);
    };

    const handleCompressAndGenerateHTML = async () => {
        if (!inputCode) return;
        
        setProcessing(true);
        setError(null);
        setProcessingMessage('Compressing code...');

        try {
            const originalSize = new TextEncoder().encode(inputCode).length;
            const compressed = fflate.gzipSync(new TextEncoder().encode(inputCode));
            const compressedSize = compressed.length;
            const base64Encoded = btoa(String.fromCharCode(...compressed));
            const htmlContent = `
<script>
    import("/content/11eb484cbe7649fc2b49067c69493235cb29e09fccb5875ecc1d3981aa805142i0")
    .then(ob1 => ob1.ob1Scribe("${base64Encoded}"));
</script>
`;
            setFinalHtml(htmlContent);

            const savingsPercentage = ((originalSize - compressedSize) / originalSize * 100).toFixed(2);
            setSavings(savingsPercentage);
            setSavingsModalOpen(true);

            const formData = new FormData();
            formData.append('compressedCode', htmlContent);

            const response = await axios.post(`${BACKEND_URL}/compress`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status !== 200) {
                throw new Error('Failed to send compressed code to backend.');
            }
        } catch (error) {
            console.error('Compression error:', error);
            setError('Failed to compress code');
        } finally {
            setProcessing(false);
            setProcessingMessage('');
        }
    };
    const handleCloseSavingsModal = () => {
        setSavingsModalOpen(false);
    };

    const handleReceiverAddressChange = (event) => {
        setOrderDetails({ ...orderDetails, receiverAddress: event.target.value });
    };

    const handleFeeRateChange = (event) => {
        setOrderDetails({ ...orderDetails, feeRate: event.target.value });
    };

    const handleSubmit = async () => {
        if (!finalHtml) {
            setError('Please generate the HTML first.');
            return;
        }

        setProcessing(true);
        setProcessingMessage('Preparing inscription...');

        try {
            const blob = new Blob([finalHtml], { type: 'text/html' });
            const formData = new FormData();
            formData.append('file', blob, 'compressedCode.html');

            const response = await fetch(`${BACKEND_URL}/upload`, {
                method: 'POST',
                body: formData,
                headers: {
                    'User-Selected-Fee-Rate': orderDetails.feeRate,
                    'User-Selected-Receiver-Address': orderDetails.receiverAddress,
                },
            });

            if (response.ok) {
                const result = await response.json();
                setOrderDetails({
                    ...orderDetails,
                    totalAmount: result.payAddressAmount,
                    totalAmountBTC: result.payAddressAmount / 100000000,
                    payAddress: result.payAddress,
                    receiverAddress: result.receiverAddress,
                    devAddress: result.devAddress,
                    devFee: result.devFee,
                    feeRate: result.feeRate,
                });
                setIsModalOpen(true);
            } else {
                throw new Error('Failed to upload HTML and create order');
            }
        } catch (error) {
            console.error('Error during the fetch request:', error);
            setError('Failed to process inscription. Please try again.');
        } finally {
            setProcessing(false);
            setProcessingMessage('');
        }
    };

    const handleConfirm = async () => {
        if (!window.unisat?.sendBitcoin) {
            setError('Unisat wallet not found');
            return;
        }

        try {
            const txidPay = await window.unisat.sendBitcoin(
                orderDetails.payAddress, 
                orderDetails.totalAmount, 
                { feeRate: parseInt(orderDetails.feeRate) }
            );
            console.log('Payment transaction successful. TXID:', txidPay);
            alert('Transaction successful! TXID: ' + txidPay);
            setIsModalOpen(false);
        } catch (error) {
            console.error('Transaction failed:', error);
            setError('Transaction failed. Please try again or check your wallet.');
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="min-h-screen bg-gray-100 py-8 px-4">
            <div className="max-w-4xl mx-auto bg-white rounded-xl shadow-2xl p-8">
                <h1 className="text-3xl font-bold text-center mb-2">HTML Code Compressor</h1>
                <p className="text-center text-gray-600 mb-8">Powered by OCM Library</p>

                {error && (
                    <div className="mb-4 p-4 bg-red-100 text-red-700 rounded-lg">
                        {error}
                    </div>
                )}

                <div className="space-y-6">
                    {/* Code Input Section */}
                    <div className="flex flex-col">
                        <label className="text-gray-700 font-semibold mb-2">Your HTML Code:</label>
                        <textarea 
                            value={inputCode}
                            onChange={handleCodeChange}
                            placeholder="Paste your HTML content here..."
                            className="w-full h-64 p-4 border rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none font-mono text-sm bg-gray-50"
                        />
                    </div>

                    {/* Processing Indicator */}
                    {processing && (
                        <div className="space-y-4">
                            <div className="w-full bg-gray-200 rounded-full h-2.5">
                                <div 
                                    className="bg-blue-600 h-2.5 rounded-full transition-all duration-300" 
                                    style={{width: '100%'}}
                                />
                            </div>
                            {processingMessage && (
                                <div className="text-center text-gray-600">
                                    <p>{processingMessage}</p>
                                    <p className="text-sm mt-1">Please don't close this window</p>
                                </div>
                            )}
                        </div>
                    )}

                    {/* Compress Button */}
                    <button 
                        onClick={handleCompressAndGenerateHTML}
                        disabled={!inputCode || processing}
                        className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-xl transition duration-200 disabled:opacity-50"
                    >
                        Compress & Generate HTML
                    </button>

                    {/* Preview Section */}
                    <div className="bg-gray-800 rounded-xl p-6">
                        <h3 className="text-white text-lg font-semibold mb-4">Live Preview:</h3>
                        <iframe
                            key={iframeKey}
                            ref={iframeRef}
                            className="w-full h-[500px] border-2 border-gray-700 rounded-xl bg-black"
                            title="Ordinal Content Viewer"
                        />
                    </div>

                    {/* Inscription Details */}
                    <div className="grid grid-cols-1 gap-6 mt-8">
                        <div className="space-y-4">
                            {/* Receiver Address Input */}
                            <div className="flex flex-col">
                                <label className="text-gray-700 font-semibold mb-2">Receiver Address:</label>
                                <input 
                                    type="text" 
                                    value={orderDetails.receiverAddress} 
                                    onChange={handleReceiverAddressChange}
                                    className="border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    placeholder="Enter receiver address"
                                />
                            </div>

                            {/* Fee Rate Input */}
                            <div className="flex flex-col">
                                <label className="text-gray-700 font-semibold mb-2">Fee Rate (sat/vB):</label>
                                <input 
                                    type="number" 
                                    value={orderDetails.feeRate} 
                                    onChange={handleFeeRateChange}
                                    min="1"
                                    className="border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                                {recommendedFees.fastestFee && (
                                    <div className="mt-2 text-sm text-gray-600">
                                        <p>Fastest: {recommendedFees.fastestFee} sat/vB</p>
                                        <p>Half Hour: {recommendedFees.halfHourFee} sat/vB</p>
                                        <p>Hour: {recommendedFees.hourFee} sat/vB</p>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Inscribe Button */}
                        <button 
                            onClick={handleSubmit}
                            disabled={!finalHtml || processing}
                            className="w-full bg-purple-500 hover:bg-purple-600 text-white font-bold py-3 px-6 rounded-xl transition duration-200 disabled:opacity-50"
                        >
                            Inscribe Compressed Code
                        </button>
                    </div>
                </div>
            </div>

            {/* Modals */}
            <SavingsModal 
                isOpen={savingsModalOpen} 
                onClose={handleCloseSavingsModal} 
                savings={savings} 
            />

            <ConfirmPurchaseModal
                isOpen={isModalOpen}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
                paymentDetails={{
                    payAddressAmount: orderDetails.totalAmount,
                    payAddress: orderDetails.payAddress,
                    receiverAddress: orderDetails.receiverAddress,
                    feeRate: orderDetails.feeRate
                }}
            />
        </div>
    );
};

export default HTMLCompressMagic;