import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import PillAnimation from './PillAnimation'; 
import ConfirmPurchaseModal from './ConfirmPurchaseModal'; 

const BACKEND_URL = 'https://inscription-service-production.up.railway.app';


const satoshiToBitcoin = (satoshis) => {
    return (satoshis / 100000000).toFixed(8); 
};

const InscriptionForm = () => {
    const [file, setFile] = useState(null);
    const [orderDetails, setOrderDetails] = useState({
        totalAmount: 0,
        totalAmountBTC: 0, 
        payAddress: '',
        receiverAddress: '',
        devAddress: '',
        devFee: 0,
        feeRate: 10,
    });
    const [recommendedFees, setRecommendedFees] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false); 

    useEffect(() => {
        const fetchRecommendedFees = async () => {
            try {
                const response = await axios.get('https://mempool.space/api/v1/fees/recommended');
                setRecommendedFees(response.data);
            } catch (error) {
                console.error('Error fetching recommended fees:', error);
            }
        };

        fetchRecommendedFees();
    }, []);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
    };

    const handleFeeRateChange = (event) => {
        setOrderDetails({ ...orderDetails, feeRate: event.target.value });
    };

    const handleReceiverAddressChange = (event) => {
        setOrderDetails({ ...orderDetails, receiverAddress: event.target.value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!file) {
            alert("Please select a file.");
            return;
        }

        const formData = new FormData();
        formData.append("file", file);

        

        try {
            const response = await fetch(`${BACKEND_URL}/upload`, {
                method: 'POST',
                body: formData,
                headers: {
                    
                    'User-Selected-Fee-Rate': orderDetails.feeRate,
                    'User-Selected-Receiver-Address': orderDetails.receiverAddress,
                }
            });

            if (response.ok) {
                const result = await response.json();
                const bitcoinAmount = satoshiToBitcoin(result.payAddressAmount); 

                setOrderDetails({
                    ...orderDetails,
                    totalAmount: result.payAddressAmount,
                    totalAmountBTC: bitcoinAmount, 
                    payAddress: result.payAddress,
                    receiverAddress: result.receiverAddress,
                    devAddress: result.devAddress,
                    devFee: result.devFee,
                    feeRate: result.feeRate
                });

                setIsModalOpen(true); 
            } else {
                alert('Failed to upload file and create order. Please try again.');
            }
        } catch (error) {
            alert('An error occurred while processing your request.');
            console.error("Error during the fetch request:", error);
        }
    };

    const handleConfirm = async () => {
        if (window.unisat && window.unisat.sendBitcoin) {
            try {
                const txidPay = await window.unisat.sendBitcoin(orderDetails.payAddress, orderDetails.totalAmount, { feeRate: parseInt(orderDetails.feeRate) });
                console.log('Payment transaction successful. TXID:', txidPay);
                alert('Transaction successful! TXID: ' + txidPay);
                setIsModalOpen(false); 
            } catch (error) {
                console.error('Transaction failed:', error);
                alert('Transaction failed. Please try again or check your wallet.');
            }
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false); 
    };

    return (
        <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="relative min-h-[calc(100vh-80px)] bg-gradient-to-b from-gray-900 via-purple-900 to-black p-6 pt-4"
        >
            <div className="absolute inset-0 overflow-hidden">
                <PillAnimation className="opacity-50" />
            </div>
            
            <motion.div 
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                className="max-w-2xl mx-auto bg-white/95 backdrop-blur-sm rounded-2xl shadow-xl border border-purple-500/20 p-6 relative z-10 mt-4"
            >
                <h2 className="text-2xl font-bold text-black mb-6 text-center">Create Inscription</h2>
                
                <form onSubmit={handleSubmit} className="space-y-4">
                    {/* File Upload Section */}
                    <div className="space-y-3">
                        <div className="relative group">
                            <input
                                id="file-upload"
                                type="file"
                                onChange={handleFileChange}
                                className="hidden"
                            />
                            <label
                                htmlFor="file-upload"
                                className="flex items-center justify-center w-full h-24 px-4 transition bg-gradient-to-r from-purple-50 to-pink-50 
                                         border-2 border-purple-300 border-dashed rounded-xl hover:bg-purple-100 cursor-pointer"
                            >
                                <div className="space-y-1 text-center">
                                    <svg className="mx-auto h-8 w-8 text-purple-500" stroke="currentColor" fill="none" viewBox="0 0 48 48">
                                        <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <div className="text-sm text-gray-600">
                                        <span className="font-medium text-purple-600 hover:text-purple-500">
                                            Upload a file
                                        </span>
                                        {' '}or drag and drop
                                    </div>
                                </div>
                            </label>
                        </div>

                        {file && (
                            <motion.div 
                                initial={{ opacity: 0, y: 10 }}
                                animate={{ opacity: 1, y: 0 }}
                                className="p-3 bg-purple-50 rounded-xl space-y-1"
                            >
                                <p className="text-sm text-gray-600">File: <span className="font-medium text-black">{file.name}</span></p>
                                <p className="text-sm text-gray-600">Size: <span className="font-medium text-black">{(file.size / 1024).toFixed(2)} KB</span></p>
                            </motion.div>
                        )}
                    </div>

                    {/* Receiver Address Input */}
                    <div className="space-y-1">
                        <label className="block text-sm font-medium text-gray-700">Receiver Address</label>
                        <input
                            type="text"
                            value={orderDetails.receiverAddress}
                            onChange={handleReceiverAddressChange}
                            className="w-full px-4 py-2 bg-white border border-purple-500/30 rounded-lg 
                                     text-black placeholder-gray-400 focus:outline-none focus:ring-2 
                                     focus:ring-purple-500 focus:border-transparent transition-all duration-200"
                            placeholder="Enter receiver address"
                        />
                    </div>

                    {/* Fee Rate Section */}
                    <div className="space-y-3">
                        <div className="space-y-1">
                            <label className="block text-sm font-medium text-gray-700">Fee Rate (sat/vB)</label>
                            <input
                                type="number"
                                value={orderDetails.feeRate}
                                onChange={handleFeeRateChange}
                                min="1"
                                className="w-full px-4 py-2 bg-white border border-purple-500/30 rounded-lg 
                                         text-black placeholder-gray-400 focus:outline-none focus:ring-2 
                                         focus:ring-purple-500 focus:border-transparent transition-all duration-200"
                            />
                        </div>

                        {recommendedFees.fastestFee && (
                            <div className="grid grid-cols-3 gap-3">
                                <div className="p-2 bg-gradient-to-r from-purple-50 to-pink-50 rounded-xl text-center">
                                    <p className="text-xs text-gray-600">Fastest</p>
                                    <p className="text-base font-bold text-purple-600">{recommendedFees.fastestFee}</p>
                                    <p className="text-xs text-gray-500">sat/vB</p>
                                </div>
                                <div className="p-2 bg-gradient-to-r from-purple-50 to-pink-50 rounded-xl text-center">
                                    <p className="text-xs text-gray-600">Half Hour</p>
                                    <p className="text-base font-bold text-purple-600">{recommendedFees.halfHourFee}</p>
                                    <p className="text-xs text-gray-500">sat/vB</p>
                                </div>
                                <div className="p-2 bg-gradient-to-r from-purple-50 to-pink-50 rounded-xl text-center">
                                    <p className="text-xs text-gray-600">Hour</p>
                                    <p className="text-base font-bold text-purple-600">{recommendedFees.hourFee}</p>
                                    <p className="text-xs text-gray-500">sat/vB</p>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Order Summary */}
                    {file && orderDetails.totalAmount > 0 && (
                        <motion.div 
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            className="p-3 bg-gradient-to-r from-purple-600 to-pink-600 rounded-xl text-white space-y-1"
                        >
                            <h3 className="font-bold text-base mb-2">Order Summary</h3>
                            <p className="flex justify-between text-sm">
                                <span>Total Fee:</span>
                                <span className="font-bold">{orderDetails.totalAmount} sats</span>
                            </p>
                            <p className="flex justify-between text-sm">
                                <span>Network Fee Rate:</span>
                                <span className="font-bold">{orderDetails.feeRate} sat/vB</span>
                            </p>
                        </motion.div>
                    )}

                    {/* Submit Button */}
                    <motion.button
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        type="submit"
                        className="w-full py-3 bg-gradient-to-r from-purple-600 to-pink-600 rounded-xl
                                 font-bold text-white hover:shadow-lg hover:shadow-purple-500/25 
                                 transition-all duration-200 relative overflow-hidden group"
                    >
                        <span className="relative z-10">Submit Inscription</span>
                        <motion.div
                            className="absolute inset-0 bg-gradient-to-r from-purple-700 to-pink-700 opacity-0 
                                     group-hover:opacity-100 transition-opacity duration-300"
                        />
                    </motion.button>
                </form>
            </motion.div>

            <ConfirmPurchaseModal
                isOpen={isModalOpen}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
                paymentDetails={{
                    payAddressAmount: orderDetails.totalAmount,
                    payAddress: orderDetails.payAddress,
                    receiverAddress: orderDetails.receiverAddress,
                    feeRate: orderDetails.feeRate
                }}
            />
        </motion.div>
    );
};

export default InscriptionForm;