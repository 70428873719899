import React, { useState } from 'react';
import NavMenu from './NavMenu';
import WalletConnector from './WalletConnector';

const Header = () => {
    const [isNavVisible, setIsNavVisible] = useState(false);

    const toggleMenu = () => {
        setIsNavVisible(!isNavVisible);
    };

    return (
        <>
            <header className="fixed top-0 left-0 right-0 bg-white/90 backdrop-blur-sm shadow-sm z-50">
                <div className="max-w-full mx-auto">
                    <div className="flex items-center h-16">
                        {/* Left Section with Hamburger */}
                        <div className="flex items-center pl-4">
                            <button 
                                className="relative group p-2 rounded-lg hover:bg-gray-100 transition-colors"
                                onClick={toggleMenu}
                                aria-label="Menu"
                            >
                                <div className="relative flex overflow-hidden items-center justify-center w-[20px] h-[20px]">
                                    <div className={`transform transition-all duration-200 ease-in-out ${
                                        isNavVisible 
                                            ? 'rotate-45 translate-y-1.5' 
                                            : ''
                                    } absolute w-full h-[2px] bg-gray-800 transform-origin-center`} />
                                    
                                    <div className={`transform transition-all duration-200 ease-in-out ${
                                        isNavVisible 
                                            ? 'opacity-0' 
                                            : ''
                                    } absolute w-full h-[2px] bg-gray-800`} />
                                    
                                    <div className={`transform transition-all duration-200 ease-in-out ${
                                        isNavVisible 
                                            ? '-rotate-45 -translate-y-1.5' 
                                            : ''
                                    } absolute w-full h-[2px] bg-gray-800 transform-origin-center`} />
                                </div>
                            </button>
                        </div>

                        {/* Center Section with Title */}
                        <div className="flex-1 flex justify-center">
                            <a 
                                href="http://www.spacescribe.xyz" 
                                className="text-lg font-semibold text-gray-800 hover:text-purple-600 transition-colors"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                spacescribe.xyz
                            </a>
                        </div>

                        {/* Right Section with Wallet */}
                        <div className="pr-4">
                            <div className="transform hover:scale-105 transition-transform">
                                <WalletConnector 
                                    onConnected={(address) => console.log('Connected:', address)}
                                    className="bg-gradient-to-r from-purple-600 to-blue-500 text-white px-4 py-2 rounded-full shadow-lg hover:shadow-xl transition-all duration-300 font-medium"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {/* Spacer to prevent content from hiding under fixed header */}
            <div className="h-16"></div>

            {/* Navigation Menu */}
            <NavMenu isVisible={isNavVisible} toggleMenu={toggleMenu} />
        </>
    );
};

export default Header;