import React, { useState } from 'react';
import { connectWallet } from '../api/walletApi';
import { motion } from 'framer-motion';

const WalletConnector = ({ onConnected }) => {
    const [walletAddress, setWalletAddress] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isHovering, setIsHovering] = useState(false);

    const handleConnect = async () => {
        setIsLoading(true);
        try {
            const accounts = await connectWallet();
            if (accounts && accounts.length > 0) {
                setWalletAddress(accounts[0]);
                onConnected(accounts[0]);
            } else {
                console.error('No accounts found. Make sure the wallet is connected and the correct network is selected.');
            }
        } catch (error) {
            console.error('Error connecting to wallet:', error);
        }
        setIsLoading(false);
    };

    const formatWalletAddress = (address) => {
        if (!address) return '';
        return `${address.slice(0, 4)}...${address.slice(-4)}`;
    };

    return (
        <div className="flex items-center gap-4">
            {!walletAddress ? (
                <motion.button 
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={handleConnect}
                    disabled={isLoading}
                    onMouseEnter={() => setIsHovering(true)}
                    onMouseLeave={() => setIsHovering(false)}
                    className="relative px-6 py-2 bg-gradient-to-r from-purple-600 to-pink-600 
                             rounded-xl font-bold text-white hover:shadow-lg 
                             hover:shadow-purple-500/25 transition-all duration-200
                             disabled:opacity-50 disabled:cursor-not-allowed"
                >
                    <span className="relative z-10 flex items-center gap-2">
                        {isLoading ? (
                            <>
                                <svg className="animate-spin h-4 w-4" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none"/>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                                </svg>
                                Connecting...
                            </>
                        ) : (
                            <>
                                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"/>
                                </svg>
                                Connect Wallet
                            </>
                        )}
                    </span>
                    <div className="absolute inset-0 bg-gradient-to-r from-purple-700 to-pink-700 
                                  opacity-0 group-hover:opacity-100 transition-opacity duration-300 
                                  rounded-xl"/>
                </motion.button>
            ) : (
                <motion.div 
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="px-4 py-2 bg-white/10 backdrop-blur-sm rounded-xl 
                             border border-purple-500/20 text-white flex items-center gap-2"
                >
                    <div className="w-2 h-2 bg-green-400 rounded-full animate-pulse"/>
                    <span className="text-sm font-medium">
                        {formatWalletAddress(walletAddress)}
                    </span>
                </motion.div>
            )}
        </div>
    );
};

export default WalletConnector;