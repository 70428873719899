import React, { useState, useEffect, useCallback } from 'react';

const InscriptionBlock = () => {
  const [inscriptions, setInscriptions] = useState([]);
  const [currentBlockHeight, setCurrentBlockHeight] = useState('');
  const [error, setError] = useState(null);
  const [selectedInscriptionId, setSelectedInscriptionId] = useState(null);

  const fetchInscriptionTransfers = useCallback(async (height) => {
    const apiUrl = `https://api.hiro.so/ordinals/v1/inscriptions/transfers?block=${height}&limit=60`;
    try {
      const response = await fetch(apiUrl, {
        headers: { 'Accept': 'application/json' }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch inscriptions');
      }
      const data = await response.json();
      setInscriptions(data.results);
    } catch (err) {
      setError(`Inscriptions Fetch Error: ${err.message}`);
    }
  }, []);

  const fetchCurrentBlockHeight = useCallback(async () => {
    try {
      const response = await fetch('https://mempool.space/api/blocks/tip/height');
      if (!response.ok) {
        throw new Error('Failed to fetch current block height');
      }
      const height = await response.text();
      setCurrentBlockHeight(height);
      setInscriptions([]);
      fetchInscriptionTransfers(height);
    } catch (err) {
      setError(`Block Height Fetch Error: ${err.message}`);
    }
  }, [fetchInscriptionTransfers]);

  useEffect(() => {
    fetchCurrentBlockHeight();
    const interval = setInterval(fetchCurrentBlockHeight, 300000);
    return () => clearInterval(interval);
  }, [fetchCurrentBlockHeight]);

  const handleInscriptionClick = (id) => {
    setSelectedInscriptionId(id === selectedInscriptionId ? null : id);
  };

  const TruncatedText = ({ label, value }) => (
    <div className="flex flex-col space-y-1">
      <span className="text-purple-300">{label}:</span>
      <span 
        className="text-white truncate hover:text-clip" 
        title={value}
      >
        {value}
      </span>
    </div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 via-purple-900 to-black p-6">
      <div className="max-w-7xl mx-auto space-y-6">
        {error && <p className="text-red-400 bg-red-900/20 border border-red-500/50 rounded-lg p-4">{error}</p>}
        {currentBlockHeight && (
          <h2 className="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-400">
            Current Block Height: {currentBlockHeight}
          </h2>
        )}

        {inscriptions.length > 0 ? (
          inscriptions.map((inscription) => (
            <div
              key={inscription.id}
              className="bg-white/5 backdrop-blur-sm rounded-xl p-6 space-y-4 border border-purple-500/20 hover:border-purple-500/40 transition-all duration-200"
            >
              <button
                className="w-full py-2 bg-gradient-to-r from-purple-600 to-pink-600 rounded-lg text-white font-bold hover:shadow-lg hover:shadow-purple-500/25 transition-all duration-200"
                onClick={() => handleInscriptionClick(inscription.id)}
              >
                {selectedInscriptionId === inscription.id ? 'Hide Details' : 'View Details'}
              </button>

              {selectedInscriptionId === inscription.id ? (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="bg-white/5 rounded-lg p-4 space-y-3">
                    <p className="text-purple-300 font-bold text-lg mb-4">Seller Details</p>
                    <TruncatedText label="ID" value={inscription.id} />
                    <TruncatedText label="Block Height" value={inscription.from.block_height} />
                    <TruncatedText label="Block Hash" value={inscription.from.block_hash} />
                    <TruncatedText label="Address" value={inscription.from.address} />
                    <TruncatedText label="Transaction ID" value={inscription.from.tx_id} />
                    <TruncatedText label="Location" value={inscription.from.location} />
                    <TruncatedText label="Output" value={inscription.from.output} />
                    <TruncatedText label="Value" value={inscription.from.value} />
                    <TruncatedText 
                      label="Timestamp" 
                      value={new Date(inscription.from.timestamp).toLocaleString()} 
                    />
                  </div>
                  <div className="bg-white/5 rounded-lg p-4 space-y-3">
                    <p className="text-purple-300 font-bold text-lg mb-4">Buyer Details</p>
                    <TruncatedText label="ID" value={inscription.id} />
                    <TruncatedText label="Block Height" value={inscription.to.block_height} />
                    <TruncatedText label="Block Hash" value={inscription.to.block_hash} />
                    <TruncatedText label="Address" value={inscription.to.address} />
                    <TruncatedText label="Transaction ID" value={inscription.to.tx_id} />
                    <TruncatedText label="Location" value={inscription.to.location} />
                    <TruncatedText label="Output" value={inscription.to.output} />
                    <TruncatedText label="Value" value={inscription.to.value} />
                    <TruncatedText 
                      label="Timestamp" 
                      value={new Date(inscription.to.timestamp).toLocaleString()} 
                    />
                  </div>
                </div>
              ) : (
                <div className="relative">
                  <iframe
                    className="w-full h-64 rounded-lg"
                    src={`https://ordinals.com/preview/${inscription.id}`}
                    title={`Inscription Preview ${inscription.id}`}
                    frameBorder="0"
                    loading="lazy"
                  ></iframe>
                  <div className="absolute bottom-2 left-2 right-2">
                    <p className="text-xs bg-black/50 text-white p-2 rounded truncate">
                      ID: {inscription.id}
                    </p>
                  </div>
                </div>
              )}
            </div>
          ))
        ) : (
          <p className="text-center text-gray-400 py-10 bg-white/5 rounded-lg">
            No inscriptions found for the current block.
          </p>
        )}
      </div>
    </div>
  );
};

export default InscriptionBlock;