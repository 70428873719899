import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { minify } from 'terser';
import * as fflate from 'fflate';
import './VideoCompressMagic.css';
import ConfirmPurchaseModal from './ConfirmPurchaseModal';

const BACKEND_URL = 'https://inscription-service-production.up.railway.app';

const SavingsModal = ({ isOpen, onClose, savings }) => {
    if (!isOpen) return null;
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-xl p-8 max-w-md w-full mx-4 shadow-2xl">
                <h4 className="text-2xl font-bold mb-4 text-gray-800">Compression Results</h4>
                <div className="mb-6">
                    <div className="text-4xl font-bold text-green-500 mb-2">{savings}%</div>
                    <p className="text-gray-600">File size reduction achieved</p>
                </div>
                <button 
                    onClick={onClose}
                    className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded-lg transition duration-200"
                >
                    Close
                </button>
            </div>
        </div>
    );
};

const VideoCompressMagic = () => {
    // Original state declarations
    const [videoFile, setVideoFile] = useState(null);
    const [inputCode, setInputCode] = useState('');
    const [finalHtml, setFinalHtml] = useState('');
    const [orderDetails, setOrderDetails] = useState({
        totalAmount: 0,
        totalAmountBTC: '',
        payAddress: '',
        receiverAddress: '',
        devAddress: '',
        devFee: 0,
        feeRate: 10,
    });
    const [recommendedFees, setRecommendedFees] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [savingsModalOpen, setSavingsModalOpen] = useState(false);
    const [savings, setSavings] = useState(0);
    const [iframeKey, setIframeKey] = useState(0);
    const iframeRef = useRef(null);

    // New state for progress tracking
    const [processing, setProcessing] = useState(false);
    const [progress, setProgress] = useState(0);
    const [processingMessage, setProcessingMessage] = useState('');
    const [error, setError] = useState(null);

    // Fetch recommended fees
    useEffect(() => {
        const fetchRecommendedFees = async () => {
            try {
                const response = await axios.get('https://mempool.space/api/v1/fees/recommended');
                setRecommendedFees(response.data);
            } catch (error) {
                console.error('Error fetching recommended fees:', error);
                setError('Failed to fetch recommended fees');
            }
        };
        fetchRecommendedFees();
    }, []);
    // K-means clustering implementation
        // Original k-means clustering implementation
        const kmeans = (data, k) => {
            const centroids = data.slice(0, k);
            let clusters = new Array(data.length).fill(0);
            let newCentroids = Array.from({ length: k }, () => [0, 0, 0]);
            let changed = true;
    
            while (changed) {
                changed = false;
                for (let i = 0; i < data.length; i++) {
                    const distances = centroids.map(centroid =>
                        Math.sqrt(data[i].reduce((sum, val, idx) => sum + Math.pow(val - centroid[idx], 2), 0))
                    );
                    const newCluster = distances.indexOf(Math.min(...distances));
                    if (newCluster !== clusters[i]) {
                        clusters[i] = newCluster;
                        changed = true;
                    }
                }
    
                newCentroids = Array.from({ length: k }, () => [0, 0, 0]);
                const counts = new Array(k).fill(0);
                for (let i = 0; i < data.length; i++) {
                    newCentroids[clusters[i]] = newCentroids[clusters[i]].map((val, idx) => val + data[i][idx]);
                    counts[clusters[i]]++;
                }
                for (let i = 0; i < k; i++) {
                    if (counts[i] > 0) {
                        newCentroids[i] = newCentroids[i].map(val => Math.floor(val / counts[i]));
                    }
                }
                centroids.forEach((_, idx) => centroids[idx] = newCentroids[idx]);
            }
            return { clusters, centroids };
        };
    
        // Original processFrame function
        const processFrame = (video, targetSize, colorClusters) => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = targetSize;
            canvas.height = targetSize;
    
            ctx.drawImage(video, 0, 0, targetSize, targetSize);
            const imageData = ctx.getImageData(0, 0, targetSize, targetSize);
            const pixels = [];
            for (let y = 0; y < imageData.height; y++) {
                for (let x = 0; x < imageData.width; x++) {
                    const index = (x + y * imageData.width) * 4;
                    const r = imageData.data[index];
                    const g = imageData.data[index + 1];
                    const b = imageData.data[index + 2];
                    pixels.push([r, g, b]);
                }
            }
    
            const { clusters, centroids } = kmeans(pixels, colorClusters);
            const encodedPixels = clusters.map(cluster => {
                const [r, g, b] = centroids[cluster];
                return `${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
            });
    
            return encodedPixels.join(';');
        };
    
        // Enhanced processVideoFrames with progress tracking
        const processVideoFrames = async (video, frameRate, targetSize, colorClusters) => {
            const frames = [];
            const interval = 1 / frameRate;
            const totalFrames = Math.floor(video.duration * frameRate);
    
            try {
                for (let i = 0; i < totalFrames; i++) {
                    video.currentTime = i * interval;
                    await new Promise(resolve => video.onseeked = resolve);
                    frames.push(processFrame(video, targetSize, colorClusters));
                    
                    const progressPercent = Math.floor((i / totalFrames) * 100);
                    setProgress(progressPercent);
                    setProcessingMessage(`Processing frame ${i + 1} of ${totalFrames} (${progressPercent}%)`);
                }
                return frames;
            } catch (error) {
                setError('Error processing video frames');
                console.error('Frame processing error:', error);
                throw error;
            }
        };
    
        // Enhanced generateP5Code with progress tracking
        const generateP5Code = useCallback(async () => {
            if (!videoFile) return;
    
            setProcessing(true);
            setError(null);
            setProgress(0);
            setProcessingMessage('Loading video file...');
    
            try {
                const video = document.createElement('video');
                video.src = videoFile;
                video.crossOrigin = 'anonymous';
    
                await new Promise((resolve) => {
                    video.onloadeddata = () => {
                        setProcessingMessage('Video loaded, starting processing...');
                        resolve();
                    };
                });
    
                const targetSize = 130;
                const colorClusters = 15;
                const frameRate = 3;
    
                const frames = await processVideoFrames(video, frameRate, targetSize, colorClusters);
                setProcessingMessage('Generating p5.js code...');
    
                const code = `
    let encodedFrames = ${JSON.stringify(frames)};
    let currentFrame = 0;
    
    function hexToRgb(hex) {
        let r = parseInt(hex.substring(0, 2), 16);
        let g = parseInt(hex.substring(2, 4), 16);
        let b = parseInt(hex.substring(4, 6), 16);
        return [r, g, b];
    }
    
    function setup() {
        createCanvas(windowWidth, windowHeight);
        frameRate(${frameRate});
        noStroke();
        pixelDensity(1);
    }
    
    function draw() {
        background(0);
        let encodedPixels = encodedFrames[currentFrame].split(';');
        let imgWidth = ${targetSize};
        let imgHeight = ${targetSize};
        let scaleWidth = width / imgWidth;
        let scaleHeight = height / imgHeight;
    
        for (let y = 0; y < imgHeight; y++) {
            for (let x = 0; x < imgWidth; x++) {
                let index = x + y * imgWidth;
                let colorHex = encodedPixels[index];
                let [r, g, b] = hexToRgb(colorHex);
                fill(r, g, b);
                rect(x * scaleWidth, y * scaleHeight, ceil(scaleWidth), ceil(scaleHeight));
            }
        }
    
        currentFrame = (currentFrame + 1) % encodedFrames.length;
    }`;
    
                setInputCode(code);
                setIframeKey(prevKey => prevKey + 1);
                setProcessing(false);
                setProcessingMessage('');
            } catch (error) {
                console.error('Error generating code:', error);
                setError('Failed to process video');
                setProcessing(false);
                setProcessingMessage('');
            }
        }, [videoFile]);

       // Display functions and handlers
       const displaySketch = useCallback(() => {
        if (!inputCode.trim()) return;

        const fullHTML = `
            <!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <script src="https://ordinals.com/content/7e37766541506810ba6399c4b2735121f508bd9209df43dd200bf2316b014594i0"></script>
                <style>
                    html, body { margin: 0; padding: 0; }
                    canvas { position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); }
                </style>
            </head>
            <body>
                <script>
                    ${inputCode}
                </script>
            </body>
            </html>
        `;

        const iframeDoc = iframeRef.current.contentDocument;
        iframeDoc.open();
        iframeDoc.write(fullHTML);
        iframeDoc.close();
    }, [inputCode]);

    useEffect(() => {
        displaySketch();
    }, [iframeKey, displaySketch]);

    // Your original handlers with added progress tracking
    const handleCompressAndGenerateHTML = async () => {
        setProcessing(true);
        setProcessingMessage('Compressing code...');
        try {
            const minifiedResult = await minify(inputCode);
            if (minifiedResult.error) {
                throw new Error('Minification failed');
            }
            const originalSize = new TextEncoder().encode(inputCode).length;
            const compressed = fflate.gzipSync(new TextEncoder().encode(minifiedResult.code));
            const compressedSize = compressed.length;
            const base64Encoded = btoa(String.fromCharCode(...compressed));

            const htmlContent = `
    <script>
        import("/content/d795ba6cf2ea7d4ed9c159e498ba2c9ad4295d8ea257fb1ee88e9244c016adc2i0")
        .then(ob1 => ob1.ob1Scribe("${base64Encoded}"));
    </script>
            `;
            setFinalHtml(htmlContent);
            const savingsPercentage = ((originalSize - compressedSize) / originalSize * 100).toFixed(2);
            setSavings(savingsPercentage);
            setSavingsModalOpen(true);
        } catch (error) {
            console.error("Compression error:", error);
            setError('Failed to compress code');
        } finally {
            setProcessing(false);
            setProcessingMessage('');
        }
    };

    // Keep your original handlers
    const handleReceiverAddressChange = (event) => {
        setOrderDetails({ ...orderDetails, receiverAddress: event.target.value });
    };

    const handleFeeRateChange = (event) => {
        setOrderDetails({ ...orderDetails, feeRate: event.target.value });
    };

    const handleSubmit = async () => {
        if (!finalHtml) {
            setError("Please generate the HTML first.");
            return;
        }

        setProcessing(true);
        setProcessingMessage('Uploading to server...');

        const blob = new Blob([finalHtml], { type: 'text/html' });
        const formData = new FormData();
        formData.append("file", blob, 'compressedCode.html');

        try {
            const response = await fetch(`${BACKEND_URL}/upload`, {
                method: 'POST',
                body: formData,
                headers: {
                    'User-Selected-Fee-Rate': orderDetails.feeRate,
                    'User-Selected-Receiver-Address': orderDetails.receiverAddress,
                }
            });

            if (response.ok) {
                const result = await response.json();
                setOrderDetails({
                    ...orderDetails,
                    totalAmount: result.payAddressAmount,
                    totalAmountBTC: result.payAddressAmount / 100000000,
                    payAddress: result.payAddress,
                    receiverAddress: result.receiverAddress,
                    devAddress: result.devAddress,
                    devFee: result.devFee,
                    feeRate: result.feeRate
                });
                setIsModalOpen(true);
            } else {
                throw new Error('Upload failed');
            }
        } catch (error) {
            console.error("Upload error:", error);
            setError('Failed to upload file');
        } finally {
            setProcessing(false);
            setProcessingMessage('');
        }
    };

    const handleConfirm = async () => {
        if (!window.unisat?.sendBitcoin) {
            setError('Unisat wallet not found');
            return;
        }

        setProcessing(true);
        setProcessingMessage('Processing payment...');

        try {
            const txidPay = await window.unisat.sendBitcoin(
                orderDetails.payAddress, 
                orderDetails.totalAmount, 
                { feeRate: parseInt(orderDetails.feeRate) }
            );
            console.log('Payment successful. TXID:', txidPay);
            alert('Transaction successful! TXID: ' + txidPay);
            setIsModalOpen(false);
        } catch (error) {
            console.error('Transaction failed:', error);
            setError('Payment failed');
        } finally {
            setProcessing(false);
            setProcessingMessage('');
        }
    };

    const handleCancel = () => setIsModalOpen(false);

    return (
        <div className="min-h-screen bg-gray-100 py-8 px-4">
            <div className="max-w-4xl mx-auto bg-white rounded-xl shadow-2xl p-8">
                <h1 className="text-3xl font-bold text-center mb-2">Video Compress Magic</h1>
                <p className="text-center text-gray-600 mb-8">Transform your videos into efficient p5.js animations</p>

                {error && (
                    <div className="mb-4 p-4 bg-red-100 text-red-700 rounded-lg">
                        {error}
                    </div>
                )}

                <div className="space-y-6">
                    <div className="flex flex-col items-center">
                        <label className="w-full max-w-md flex flex-col items-center px-6 py-8 bg-white rounded-xl shadow-lg tracking-wide border-2 border-blue-500 cursor-pointer hover:bg-blue-50 transition duration-200">
                            <svg className="w-8 h-8 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                            </svg>
                            <span className="mt-2 text-base leading-normal">Select video file</span>
                            <input type='file' className="hidden" accept="video/*" onChange={e => setVideoFile(URL.createObjectURL(e.target.files[0]))} />
                        </label>
                    </div>

                    {processing && (
                        <div className="space-y-4">
                            <div className="w-full bg-gray-200 rounded-full h-2.5">
                                <div 
                                    className="bg-blue-600 h-2.5 rounded-full transition-all duration-300" 
                                    style={{width: `${progress}%`}}
                                />
                            </div>
                            {processingMessage && (
                                <div className="text-center text-gray-600">
                                    <p>{processingMessage}</p>
                                    <p className="text-sm mt-1">Please don't close this window</p>
                                </div>
                            )}
                        </div>
                    )}

                    <div className="flex space-x-4 justify-center">
                        <button 
                            onClick={generateP5Code}
                            disabled={!videoFile || processing}
                            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-xl transition duration-200 disabled:opacity-50"
                        >
                            Generate p5.js Code
                        </button>
                        <button 
                            onClick={handleCompressAndGenerateHTML}
                            disabled={!inputCode || processing}
                            className="bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-6 rounded-xl transition duration-200 disabled:opacity-50"
                        >
                            Compress & Generate HTML
                        </button>
                    </div>

                    <div className="bg-gray-800 rounded-xl p-6">
                        <h3 className="text-white text-lg font-semibold mb-4">Preview:</h3>
                        <iframe
                            key={iframeKey}
                            ref={iframeRef}
                            className="w-full h-[500px] border-2 border-gray-700 rounded-xl bg-black"
                            title="p5.js Sketch Output"
                        />
                    </div>

                    <div className="space-y-4">
                        <div className="flex flex-col">
                            <label className="text-gray-700 font-semibold mb-2">Receiver Address:</label>
                            <input 
                                type="text" 
                                value={orderDetails.receiverAddress} 
                                onChange={handleReceiverAddressChange}
                                className="border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                placeholder="Enter receiver address"
                            />
                        </div>

                        <div className="flex flex-col">
                            <label className="text-gray-700 font-semibold mb-2">Fee Rate (sat/vB):</label>
                            <input 
                                type="number" 
                                value={orderDetails.feeRate} 
                                onChange={handleFeeRateChange}
                                min="1"
                                className="border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            {recommendedFees.fastestFee && (
                                <div className="mt-2 text-sm text-gray-600">
                                    <p>Fastest: {recommendedFees.fastestFee} sat/vB</p>
                                    <p>Half Hour: {recommendedFees.halfHourFee} sat/vB</p>
                                    <p>Hour: {recommendedFees.hourFee} sat/vB</p>
                                </div>
                            )}
                        </div>
                    </div>

                    <button 
                        onClick={handleSubmit}
                        disabled={!finalHtml || processing}
                        className="w-full bg-purple-500 hover:bg-purple-600 text-white font-bold py-3 px-6 rounded-xl transition duration-200 disabled:opacity-50"
                    >
                        Inscribe Compressed Code
                    </button>
                </div>
            </div>

            <SavingsModal 
                isOpen={savingsModalOpen} 
                onClose={() => setSavingsModalOpen(false)} 
                savings={savings} 
            />

            <ConfirmPurchaseModal
                isOpen={isModalOpen}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
                paymentDetails={{
                    payAddressAmount: orderDetails.totalAmount,
                    payAddress: orderDetails.payAddress,
                    receiverAddress: orderDetails.receiverAddress,
                    feeRate: orderDetails.feeRate
                }}
            />
        </div>
    );
};

export default VideoCompressMagic;