import React, { useRef, useEffect, useState } from 'react';
import p5 from 'p5';
import { minify } from 'terser';
import * as fflate from 'fflate';
import axios from 'axios';
import './InscribePad.css';
import ConfirmPurchaseModal from './ConfirmPurchaseModal';

const BACKEND_URL = 'https://inscription-service-production.up.railway.app';

const encodeCoordinate = (x, y) => {
  return `${Math.round(x).toString(36)},${Math.round(y).toString(36)}`;
};

const encodeColorAndWeight = (color, weight) => {
  return `${parseInt(color.slice(1), 16).toString(36)},${weight.toString(36)}`;
};

const InscribePad = () => {
  const sketchRef = useRef();
  const [drawingCoordinates, setDrawingCoordinates] = useState([]);
  const [htmlContent, setHtmlContent] = useState('');
  const [savingsPercentage, setSavingsPercentage] = useState(0);
  const [myP5Instance, setMyP5Instance] = useState(null);
  const [bgColor, setBgColor] = useState('#ffffff');
  const [drawingColor, setDrawingColor] = useState('#000000');
  const [drawingTool, setDrawingTool] = useState('fineBrush');
  const [markerSize, setMarkerSize] = useState(6);
  const [orderDetails, setOrderDetails] = useState({
    receiverAddress: '',
    feeRate: 10,
  });
  const [recommendedFees, setRecommendedFees] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [savings, setSavings] = useState(0);
  const [error, setError] = useState('');
  const [isDrawing, setIsDrawing] = useState(false);

  const toolSettings = {
    fineBrush: { weight: 2 },
    marker: { weight: markerSize },
  };
  useEffect(() => {
    const fetchRecommendedFees = async () => {
      try {
        const feesResponse = await axios.get('https://mempool.space/api/v1/fees/recommended');
        setRecommendedFees(feesResponse.data);
        setOrderDetails((prevDetails) => ({
          ...prevDetails,
          feeRate: feesResponse.data.fastestFee,
        }));
      } catch (error) {
        console.error('Error fetching recommended fees:', error);
      }
    };

    fetchRecommendedFees();
  }, []);

  const clearCanvas = () => {
    setDrawingCoordinates([]);
    setHtmlContent('');
    if (myP5Instance) {
      myP5Instance.clear();
      myP5Instance.background(bgColor);
    }
  };

  useEffect(() => {
    const sketch = (p) => {
      let canvas;
      let drawing = false;

      p.setup = () => {
        canvas = p.createCanvas(600, 600);
        canvas.parent(sketchRef.current);
        p.background(bgColor);
        redrawDrawing(p);
      };

      p.mousePressed = () => {
        if (p.mouseX >= 0 && p.mouseX <= p.width && p.mouseY >= 0 && p.mouseY <= p.height) {
          drawing = true;
          setIsDrawing(true);
        }
      };

      p.mouseReleased = () => {
        if (drawing) {
          drawing = false;
          setIsDrawing(false);
          setDrawingCoordinates((prevCoords) => [
            ...prevCoords,
            { type: 'end' }
          ]);
        }
      };

      p.mouseDragged = () => {
        if (drawing && p.mouseX >= 0 && p.mouseX <= p.width && p.mouseY >= 0 && p.mouseY <= p.height) {
          const tool = toolSettings[drawingTool];
          const newCoord = {
            pmouseX: p.pmouseX / p.width,
            pmouseY: p.pmouseY / p.height,
            mouseX: p.mouseX / p.width,
            mouseY: p.mouseY / p.height,
            color: drawingColor,
            weight: tool.weight
          };
          setDrawingCoordinates((prevCoords) => [
            ...prevCoords,
            newCoord,
          ]);

          p.stroke(drawingColor);
          p.strokeWeight(tool.weight);
          p.line(p.pmouseX, p.pmouseY, p.mouseX, p.mouseY);
        }
      };

      p.windowResized = () => {
        p.resizeCanvas(600, 600);
        redrawDrawing(p);
      };
    };

    const myP5 = new p5(sketch, sketchRef.current);
    setMyP5Instance(myP5);

    return () => {
      myP5.remove();
    };
  }, [drawingTool, drawingColor, markerSize, bgColor]);

  useEffect(() => {
    if (myP5Instance) {
      redrawDrawing(myP5Instance);
    }
  }, [bgColor]);
  const redrawDrawing = (p) => {
    p.background(bgColor);
    p.noFill();
    drawingCoordinates.forEach(coord => {
      if (coord.type === 'end') {
        p.endShape();
      } else {
        p.stroke(coord.color);
        p.strokeWeight(coord.weight);
        p.line(
          coord.pmouseX * p.width,
          coord.pmouseY * p.height,
          coord.mouseX * p.width,
          coord.mouseY * p.height
        );
      }
    });
  };

  const handleBgColorChange = (color) => {
    setBgColor(color);
  };

  const handleSubmit = async () => {
    if (!orderDetails.receiverAddress) {
      setError('Please add a receiver address.');
      return;
    }

    const encodedCoordinates = drawingCoordinates.map((coord, index, coords) => {
      if (coord.type === 'end') {
        return 'e';
      }
      if (index === 0 || coords[index - 1].type === 'end') {
        return `s${encodeCoordinate(coord.pmouseX * 600, coord.pmouseY * 600)},${encodeColorAndWeight(coord.color, coord.weight)}`;
      }
      return `d${encodeCoordinate(coord.mouseX * 600, coord.mouseY * 600)}`;
    }).join(';');

    const sketchScript = `
      function setup() {
        createCanvas(windowWidth, windowHeight);
        background('${bgColor}');
        noFill();
      }

      function draw() {
        const d = "${encodedCoordinates}".split(";");
        const o = width / 600;
        const e = height / 600;
        d.forEach((d) => {
          if (d.startsWith("e")) return endShape();
          if (d.startsWith("s")) {
            const [m, s, a, t] = d.slice(1).split(",");
            stroke("#" + parseInt(a, 36).toString(16).padStart(6, "0"));
            strokeWeight(parseInt(t, 36) * o);
            beginShape();
            vertex(parseInt(m, 36) * o, parseInt(s, 36) * e);
          } else {
            const [m, s] = d.slice(1).split(",");
            vertex(parseInt(m, 36) * o, parseInt(s, 36) * e);
          }
        });
        noLoop();
      }
    `;

    try {
      const minifiedResult = await minify(sketchScript);
      if (minifiedResult.error) throw new Error(minifiedResult.error);

      const originalSize = new TextEncoder().encode(sketchScript).length;
      const compressed = fflate.gzipSync(new TextEncoder().encode(minifiedResult.code));
      const compressedSize = compressed.length;
      const base64Encoded = btoa(String.fromCharCode(...compressed));

      const savingsPercentage = ((originalSize - compressedSize) / originalSize) * 100;
      setSavings(savingsPercentage.toFixed(2));

      const htmlContent = `
        <script>
          import("/content/d795ba6cf2ea7d4ed9c159e498ba2c9ad4295d8ea257fb1ee88e9244c016adc2i0")
          .then(ob1 => ob1.ob1Scribe("${base64Encoded}"));
        </script>
      `;

      setHtmlContent(htmlContent);

      const formData = new FormData();
      formData.append('file', new Blob([htmlContent], { type: 'text/html' }), 'compressedCode.html');

      const response = await axios.post(`${BACKEND_URL}/upload`, formData, {
        headers: {
          'User-Selected-Fee-Rate': orderDetails.feeRate,
          'User-Selected-Receiver-Address': orderDetails.receiverAddress,
        },
      });

      if (response.status === 200) {
        const result = response.data;
        setOrderDetails({
          ...orderDetails,
          totalAmount: result.payAddressAmount,
          totalAmountBTC: result.payAddressAmount / 100000000,
          payAddress: result.payAddress,
          receiverAddress: result.receiverAddress,
          devAddress: result.devAddress,
          devFee: result.devFee,
          feeRate: result.feeRate,
        });
        setIsModalOpen(true);
        clearCanvas();
      }
    } catch (error) {
      console.error('Error processing inscription:', error);
      setError('Failed to process inscription. Please try again.');
    }
  };

  const handleReceiverAddressChange = (event) => {
    setOrderDetails({ ...orderDetails, receiverAddress: event.target.value });
  };

  const handleFeeRateChange = (event) => {
    setOrderDetails({ ...orderDetails, feeRate: Number(event.target.value) });
  };

  const handleConfirm = async () => {
    if (window.unisat && window.unisat.sendBitcoin) {
      try {
        const txidPay = await window.unisat.sendBitcoin(
          orderDetails.payAddress,
          orderDetails.totalAmount,
          { feeRate: parseInt(orderDetails.feeRate) }
        );
        alert('Transaction successful! TXID: ' + txidPay);
        setIsModalOpen(false);
        clearCanvas();
      } catch (error) {
        console.error('Transaction failed:', error);
        alert('Transaction failed. Please try again or check your wallet.');
      }
    } else {
      alert('UniSat wallet is not available. Please install it.');
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 py-8 px-4">
      <div className="max-w-5xl mx-auto bg-white rounded-xl shadow-2xl p-8">
        <h1 className="text-3xl font-bold text-center mb-2">Inscribe Pad</h1>
        <p className="text-center text-gray-600 mb-8">Powered by P5.js Library</p>

        {error && (
          <div className="mb-4 p-4 bg-red-100 text-red-700 rounded-lg">
            {error}
          </div>
        )}

        <div className="space-y-6">
          <div className="bg-gray-50 rounded-xl p-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold mb-2">Background Color:</label>
              <input
                type="color"
                value={bgColor}
                onChange={(e) => handleBgColorChange(e.target.value)}
                className="h-10 w-full rounded-lg cursor-pointer"
              />
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold mb-2">Drawing Color:</label>
              <input
                type="color"
                value={drawingColor}
                onChange={(e) => setDrawingColor(e.target.value)}
                className="h-10 w-full rounded-lg cursor-pointer"
              />
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold mb-2">Tool:</label>
              <select 
                value={drawingTool} 
                onChange={(e) => setDrawingTool(e.target.value)}
                className="border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="fineBrush">Fine Tip Brush</option>
                <option value="marker">Marker</option>
              </select>
            </div>
            {drawingTool === 'marker' && (
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold mb-2">Marker Size:</label>
                <input
                  type="range"
                  min="1"
                  max="20"
                  value={markerSize}
                  onChange={(e) => setMarkerSize(Number(e.target.value))}
                  className="w-full"
                />
                <span className="text-sm text-gray-600 mt-1">{markerSize}px</span>
              </div>
            )}
            <div className="flex flex-col justify-end">
              <button 
                onClick={clearCanvas}
                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-lg transition duration-200"
              >
                Clear Canvas
              </button>
            </div>
          </div>

          <div className="bg-gray-800 rounded-xl p-6">
            <h3 className="text-white text-lg font-semibold mb-4">Drawing Canvas:</h3>
            <div className="relative w-full" style={{ paddingBottom: '100%' }}>
              <div 
                ref={sketchRef} 
                className="absolute inset-0 border-2 border-gray-700 rounded-xl bg-white"
                style={{ touchAction: 'none' }}
              />
            </div>
          </div>

          <div className="grid grid-cols-1 gap-6">
            <div className="space-y-4">
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold mb-2">Receiver Address:</label>
                <input 
                  type="text" 
                  value={orderDetails.receiverAddress} 
                  onChange={handleReceiverAddressChange}
                  className="border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter receiver address"
                />
              </div>

              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold mb-2">Fee Rate (sat/vB):</label>
                <input 
                  type="number" 
                  value={orderDetails.feeRate} 
                  onChange={handleFeeRateChange}
                  min="1"
                  className="border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                {recommendedFees.fastestFee && (
                  <div className="mt-2 text-sm text-gray-600">
                    <p>Fastest: {recommendedFees.fastestFee} sat/vB</p>
                    <p>Half Hour: {recommendedFees.halfHourFee} sat/vB</p>
                    <p>Hour: {recommendedFees.hourFee} sat/vB</p>
                  </div>
                )}
              </div>
            </div>

            <button 
              onClick={handleSubmit}
              className="w-full bg-purple-500 hover:bg-purple-600 text-white font-bold py-3 px-6 rounded-xl transition duration-200"
            >
              Compress and Inscribe
            </button>
          </div>
        </div>
      </div>

      <ConfirmPurchaseModal
        isOpen={isModalOpen}
        onConfirm={handleConfirm}
        onCancel={() => setIsModalOpen(false)}
        paymentDetails={{
          payAddressAmount: orderDetails.totalAmount,
          payAddress: orderDetails.payAddress,
          receiverAddress: orderDetails.receiverAddress,
          feeRate: orderDetails.feeRate,
        }}
      />
    </div>
  );
};

export default InscribePad;