import React, { useRef, useState, useCallback, useEffect } from 'react';
import p5 from 'p5';
import axios from 'axios';
import { minify } from 'terser';
import * as fflate from 'fflate';
import './ImageCompressMagic.css';
import ConfirmPurchaseModal from './ConfirmPurchaseModal';

const BACKEND_URL = 'https://inscription-service-production.up.railway.app';

const SavingsModal = ({ isOpen, onClose, savings }) => {
    if (!isOpen) return null;
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-xl p-8 max-w-md w-full mx-4 shadow-2xl">
                <h4 className="text-2xl font-bold mb-4 text-gray-800">Compression Results</h4>
                <div className="mb-6">
                    <div className="text-4xl font-bold text-green-500 mb-2">{savings}%</div>
                    <p className="text-gray-600">File size reduction achieved</p>
                </div>
                <button 
                    onClick={onClose}
                    className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded-lg transition duration-200"
                >
                    Close
                </button>
            </div>
        </div>
    );
};
const hexToRgb = (hex) => {
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);
    return [r, g, b];
};
const ImageCompressMagic = () => {
    const sketchRef = useRef();
    const previewRef = useRef();
    const [image, setImage] = useState(null);
    const [generatedCode, setGeneratedCode] = useState('');
    const [finalHtml, setFinalHtml] = useState('');
    const [orderDetails, setOrderDetails] = useState({
        totalAmount: 0,
        totalAmountBTC: '',
        payAddress: '',
        receiverAddress: '',
        devAddress: '',
        devFee: 0,
        feeRate: 10,
    });
    const [recommendedFees, setRecommendedFees] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [savingsModalOpen, setSavingsModalOpen] = useState(false);
    const [savings, setSavings] = useState(0);
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState(null);
    const [processingMessage, setProcessingMessage] = useState('');

    useEffect(() => {
        const fetchRecommendedFees = async () => {
            try {
                const response = await axios.get('https://mempool.space/api/v1/fees/recommended');
                setRecommendedFees(response.data);
            } catch (error) {
                console.error('Error fetching recommended fees:', error);
                setError('Failed to fetch recommended fees');
            }
        };
        fetchRecommendedFees();
    }, []);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setProcessing(true);
            setProcessingMessage('Loading image...');
            const reader = new FileReader();
            reader.onload = (e) => {
                setImage(e.target.result);
                setProcessing(false);
                setProcessingMessage('');
            };
            reader.readAsDataURL(file);
        }
    };

    const kmeans = (data, k) => {
        const centroids = data.slice(0, k);
        let clusters = new Array(data.length).fill(0);
        let newCentroids = Array.from({ length: k }, () => [0, 0, 0]);
        let changed = true;

        while (changed) {
            changed = false;

            for (let i = 0; i < data.length; i++) {
                const distances = centroids.map(centroid =>
                    Math.sqrt(data[i].reduce((sum, val, idx) => sum + Math.pow(val - centroid[idx], 2), 0))
                );
                const newCluster = distances.indexOf(Math.min(...distances));
                if (newCluster !== clusters[i]) {
                    clusters[i] = newCluster;
                    changed = true;
                }
            }

            newCentroids = Array.from({ length: k }, () => [0, 0, 0]);
            const counts = new Array(k).fill(0);
            for (let i = 0; i < data.length; i++) {
                newCentroids[clusters[i]] = newCentroids[clusters[i]].map((val, idx) => val + data[i][idx]);
                counts[clusters[i]]++;
            }
            for (let i = 0; i < k; i++) {
                if (counts[i] > 0) {
                    newCentroids[i] = newCentroids[i].map(val => Math.floor(val / counts[i]));
                }
            }
            centroids.forEach((_, idx) => centroids[idx] = newCentroids[idx]);
        }
        return { clusters, centroids };
    };

    const generateP5Code = useCallback(() => {
        if (!image) return;
        
        setProcessing(true);
        setProcessingMessage('Generating P5.js code...');

        const sketch = (p) => {
            let img;
            p.preload = () => {
                img = p.loadImage(image);
            };

            p.setup = () => {
                const container = sketchRef.current;
                const targetSize = 100; // This is for processing, not display
                p.createCanvas(container.clientWidth, container.clientHeight);
                
                // Calculate scaling to fit the canvas while maintaining aspect ratio
                const displayWidth = p.width;
                const displayHeight = p.height;
                const imgAspect = img.width / img.height;
                const canvasAspect = displayWidth / displayHeight;
                
                let scaledWidth, scaledHeight;
                if (imgAspect > canvasAspect) {
                    scaledWidth = displayWidth;
                    scaledHeight = displayWidth / imgAspect;
                } else {
                    scaledHeight = displayHeight;
                    scaledWidth = displayHeight * imgAspect;
                }

                // Center the image
                const x = (displayWidth - scaledWidth) / 2;
                const y = (displayHeight - scaledHeight) / 2;
                
                // Draw the original image
                p.image(img, x, y, scaledWidth, scaledHeight);

                // Process image for compression
                let processImg = img.get();
                processImg.resize(targetSize, targetSize);
                processImg.loadPixels();

                let pixels = [];
                for (let y = 0; y < processImg.height; y++) {
                    for (let x = 0; x < processImg.width; x++) {
                        let index = (x + y * processImg.width) * 4;
                        let r = processImg.pixels[index];
                        let g = processImg.pixels[index + 1];
                        let b = processImg.pixels[index + 2];
                        pixels.push([r, g, b]);
                    }
                }

                const colorClusters = 32;
                let { clusters, centroids } = kmeans(pixels, colorClusters);
                let encodedPixels = clusters.map(cluster => {
                    let [r, g, b] = centroids[cluster];
                    return `${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
                });

                const encodedString = encodedPixels.join(';');
                const code = `
let encodedPixels = "${encodedString}".split(';');

function hexToRgb(hex) {
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);
  return [r, g, b];
}

function setup() {
  createCanvas(windowWidth, windowHeight);
  let imgWidth = ${processImg.width};
  let imgHeight = ${processImg.height};
  let scaleWidth = width / imgWidth;
  let scaleHeight = height / imgHeight;
  noStroke();
  pixelDensity(1);

  for (let y = 0; y < imgHeight; y++) {
    for (let x = 0; x < imgWidth; x++) {
      let index = x + y * imgWidth;
      let colorHex = encodedPixels[index];
      let [r, g, b] = hexToRgb(colorHex);
      fill(r, g, b);
      rect(x * scaleWidth, y * scaleHeight, ceil(scaleWidth), ceil(scaleHeight));
    }
  }
}

function draw() {}
`;
                setGeneratedCode(code);

                // Create preview sketch
                const previewSketch = (p) => {
                    p.setup = () => {
                        const container = previewRef.current;
                        p.createCanvas(container.clientWidth, container.clientHeight);
                        
                        // Use same scaling logic for preview
                        const displayWidth = p.width;
                        const displayHeight = p.height;
                        const imgAspect = processImg.width / processImg.height;
                        const canvasAspect = displayWidth / displayHeight;
                        
                        let scaledWidth, scaledHeight;
                        if (imgAspect > canvasAspect) {
                            scaledWidth = displayWidth;
                            scaledHeight = displayWidth / imgAspect;
                        } else {
                            scaledHeight = displayHeight;
                            scaledWidth = displayHeight * imgAspect;
                        }

                        const x = (displayWidth - scaledWidth) / 2;
                        const y = (displayHeight - scaledHeight) / 2;

                        p.noStroke();
                        p.pixelDensity(1);

                        for (let y = 0; y < processImg.height; y++) {
                            for (let x = 0; x < processImg.width; x++) {
                                let index = x + y * processImg.width;
                                let colorHex = encodedPixels[index];
                                let [r, g, b] = hexToRgb(colorHex);
                                p.fill(r, g, b);
                                let px = x * (scaledWidth / processImg.width) + (displayWidth - scaledWidth) / 2;
                                let py = y * (scaledHeight / processImg.height) + (displayHeight - scaledHeight) / 2;
                                let pw = scaledWidth / processImg.width;
                                let ph = scaledHeight / processImg.height;
                                p.rect(px, py, Math.ceil(pw), Math.ceil(ph));
                            }
                        }
                    };
                };

                if (previewRef.current) {
                    new p5(previewSketch, previewRef.current);
                }
                setProcessing(false);
                setProcessingMessage('');
            };
        };

        if (sketchRef.current) {
            new p5(sketch, sketchRef.current);
        }
    }, [image]);

    const handleCompressAndGenerateHTML = async () => {
        if (!generatedCode) return;
        
        setProcessing(true);
        setProcessingMessage('Compressing code...');

        try {
            const minifiedResult = await minify(generatedCode);
            if (minifiedResult.error) {
                throw new Error('Minification error: ' + minifiedResult.error);
            }

            const originalSize = new TextEncoder().encode(generatedCode).length;
            const compressed = fflate.gzipSync(new TextEncoder().encode(minifiedResult.code));
            const compressedSize = compressed.length;
            const base64Encoded = btoa(String.fromCharCode(...compressed));
            
            const htmlContent = `
                <script>
                    import("/content/523cbe494d52b1ed6d24180d9066c2fe53f560b00576ee3bf4e2a8d05df9d19fi0")
                    .then(p5 => p5.remix("${base64Encoded}"));
                </script>
            `;
            
            setFinalHtml(htmlContent);
            const savingsPercentage = ((originalSize - compressedSize) / originalSize * 100).toFixed(2);
            setSavings(savingsPercentage);
            setSavingsModalOpen(true);
        } catch (error) {
            console.error("Minification and compression error:", error);
            setError('Failed to compress code: ' + error.message);
        } finally {
            setProcessing(false);
            setProcessingMessage('');
        }
    };

    const handleReceiverAddressChange = (event) => {
        setOrderDetails({ ...orderDetails, receiverAddress: event.target.value });
    };

    const handleFeeRateChange = (event) => {
        setOrderDetails({ ...orderDetails, feeRate: event.target.value });
    };

    const handleSubmit = async () => {
        if (!finalHtml) {
            setError('Please generate the HTML first.');
            return;
        }

        setProcessing(true);
        setProcessingMessage('Preparing inscription...');

        try {
            const blob = new Blob([finalHtml], { type: 'text/html' });
            const formData = new FormData();
            formData.append('file', blob, 'compressedCode.html');

            const response = await fetch(`${BACKEND_URL}/upload`, {
                method: 'POST',
                body: formData,
                headers: {
                    'User-Selected-Fee-Rate': orderDetails.feeRate,
                    'User-Selected-Receiver-Address': orderDetails.receiverAddress,
                }
            });

            if (response.ok) {
                const result = await response.json();
                setOrderDetails({
                    ...orderDetails,
                    totalAmount: result.payAddressAmount,
                    totalAmountBTC: result.payAddressAmount / 100000000,
                    payAddress: result.payAddress,
                    receiverAddress: result.receiverAddress,
                    devAddress: result.devAddress,
                    devFee: result.devFee,
                    feeRate: result.feeRate
                });
                setIsModalOpen(true);
            } else {
                throw new Error('Failed to upload HTML and create order');
            }
        } catch (error) {
            console.error("Error during the fetch request:", error);
            setError('Failed to process inscription: ' + error.message);
        } finally {
            setProcessing(false);
            setProcessingMessage('');
        }
    };

    const handleConfirm = async () => {
        if (!window.unisat?.sendBitcoin) {
            setError('Unisat wallet not found');
            return;
        }

        try {
            const txidPay = await window.unisat.sendBitcoin(
                orderDetails.payAddress, 
                orderDetails.totalAmount, 
                { feeRate: parseInt(orderDetails.feeRate) }
            );
            console.log('Payment transaction successful. TXID:', txidPay);
            alert('Transaction successful! TXID: ' + txidPay);
            setIsModalOpen(false);
        } catch (error) {
            console.error('Transaction failed:', error);
            setError('Transaction failed: ' + error.message);
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="min-h-screen bg-gray-100 py-8 px-4">
            <div className="max-w-4xl mx-auto bg-white rounded-xl shadow-2xl p-8">
                <h1 className="text-3xl font-bold text-center mb-2">Image to P5.js Converter</h1>
                <p className="text-center text-gray-600 mb-8">Powered by P5.js Library</p>

                {error && (
                    <div className="mb-4 p-4 bg-red-100 text-red-700 rounded-lg">
                        {error}
                    </div>
                )}

                <div className="space-y-6">
                    {/* Image Upload Section */}
                    <div className="flex flex-col items-center">
                        <label className="w-full flex flex-col items-center px-4 py-6 bg-gray-50 text-gray-700 rounded-xl border-2 border-dashed border-gray-300 cursor-pointer hover:bg-gray-100 transition duration-200">
                            <svg className="w-8 h-8 mb-2 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
                            </svg>
                            <span className="text-sm">Upload Image</span>
                            <input 
                                type="file" 
                                accept="image/*" 
                                onChange={handleImageUpload} 
                                className="hidden"
                            />
                        </label>
                    </div>

                    {/* Processing Indicator */}
                    {processing && (
                        <div className="space-y-4">
                            <div className="w-full bg-gray-200 rounded-full h-2.5">
                                <div 
                                    className="bg-blue-600 h-2.5 rounded-full transition-all duration-300" 
                                    style={{width: '100%'}}
                                />
                            </div>
                            {processingMessage && (
                                <div className="text-center text-gray-600">
                                    <p>{processingMessage}</p>
                                    <p className="text-sm mt-1">Please don't close this window</p>
                                </div>
                            )}
                        </div>
                    )}

                    {/* Action Buttons */}
                    <div className="flex gap-4">
                        <button 
                            onClick={generateP5Code}
                            disabled={!image || processing}
                            className="flex-1 bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-xl transition duration-200 disabled:opacity-50"
                        >
                            Generate P5.js Code
                        </button>
                        <button 
                            onClick={handleCompressAndGenerateHTML}
                            disabled={!generatedCode || processing}
                            className="flex-1 bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-xl transition duration-200 disabled:opacity-50"
                        >
                            Compress & Generate HTML
                        </button>
                    </div>

                    {/* Preview Section */}
                    <div className="bg-gray-800 rounded-xl p-6">
                        <h3 className="text-white text-lg font-semibold mb-4">Original Image:</h3>
                        <div ref={sketchRef} className="w-full h-[400px] border-2 border-gray-700 rounded-xl bg-black mb-6"/>
                        <h3 className="text-white text-lg font-semibold mb-4">Compressed Preview:</h3>
                        <div ref={previewRef} className="w-full h-[400px] border-2 border-gray-700 rounded-xl bg-black"/>
                    </div>

                    {/* Inscription Details */}
                    <div className="grid grid-cols-1 gap-6 mt-8">
                        <div className="space-y-4">
                            {/* Receiver Address Input */}
                            <div className="flex flex-col">
                                <label className="text-gray-700 font-semibold mb-2">Receiver Address:</label>
                                <input 
                                    type="text" 
                                    value={orderDetails.receiverAddress} 
                                    onChange={handleReceiverAddressChange}
                                    className="border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    placeholder="Enter receiver address"
                                />
                            </div>

                            {/* Fee Rate Input */}
                            <div className="flex flex-col">
                                <label className="text-gray-700 font-semibold mb-2">Fee Rate (sat/vB):</label>
                                <input 
                                    type="number" 
                                    value={orderDetails.feeRate} 
                                    onChange={handleFeeRateChange}
                                    min="1"
                                    className="border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                                {recommendedFees.fastestFee && (
                                    <div className="mt-2 text-sm text-gray-600">
                                        <p>Fastest: {recommendedFees.fastestFee} sat/vB</p>
                                        <p>Half Hour: {recommendedFees.halfHourFee} sat/vB</p>
                                        <p>Hour: {recommendedFees.hourFee} sat/vB</p>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Inscribe Button */}
                        <button 
                            onClick={handleSubmit}
                            disabled={!finalHtml || processing}
                            className="w-full bg-purple-500 hover:bg-purple-600 text-white font-bold py-3 px-6 rounded-xl transition duration-200 disabled:opacity-50"
                        >
                            Inscribe Compressed Code
                        </button>
                    </div>
                </div>
            </div>

            {/* Modals */}
            <SavingsModal 
                isOpen={savingsModalOpen} 
                onClose={() => setSavingsModalOpen(false)} 
                savings={savings} 
            />

            <ConfirmPurchaseModal
                isOpen={isModalOpen}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
                paymentDetails={{
                    payAddressAmount: orderDetails.totalAmount,
                    payAddress: orderDetails.payAddress,
                    receiverAddress: orderDetails.receiverAddress,
                    feeRate: orderDetails.feeRate
                }}
            />
        </div>
    );
};

export default ImageCompressMagic;