import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const Home = () => {
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const iframeSources = [
        "https://ordinals.com/preview/8a0b2127624a8d51e3e35f17d5e5583cd81a47184fc3bbbd1b3160927ed9f887i0",
        "https://ordinals.com/preview/83dc430d12ff87e5c48049f534e94e77472ebfc8592e70972acadabec7888db7i0",
        "https://ordinals.com/preview/df7d3674cd1c356e4476c718dc2feffc6f198588310891cb2d17a92e3b85bec3i0",
        "https://ordinals.com/preview/1f92d38f3968f89a85e76ae6f6fa0ca0b36c33c965cc15b5fa25b6d1e64d8374i0",
    ];

    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentSlide((prev) => (prev + 1) % iframeSources.length);
        }, 5000);
        return () => clearInterval(timer);
    }, []);

    const handleNavigateToGallery = () => {
        navigate('/gallery');
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-gray-900 via-purple-900 to-black">
            {/* Hero Section */}
            <section className="relative min-h-screen flex items-center">
                <div className="absolute inset-0 overflow-hidden">
                    <div className="absolute inset-0 bg-gradient-radial from-purple-900/30 via-gray-900/60 to-black/90" />
                </div>
                
                <div className="container mx-auto px-4 py-20 flex flex-col lg:flex-row items-center gap-12 relative z-10">
                    {/* Text Content */}
                    <motion.div 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        className="flex-1 space-y-8"
                    >
                        <h1 className="text-6xl md:text-7xl font-bold text-black">
                            Expand<br/>Imaginations
                        </h1>
                        <p className="text-xl text-black leading-relaxed max-w-2xl font-semibold">
                            Welcome to <span className="text-purple-700 font-bold">Space Scribe</span>, 
                            where p5.js and three.js artworks transform into unique digital artifacts on 
                            the Bitcoin blockchain through Ordinals.
                        </p>
                        <div className="flex flex-wrap gap-4">
                            <motion.button 
                                whileHover={{ 
                                    scale: 1.05,
                                    boxShadow: "0 0 20px rgba(168, 85, 247, 0.4)"
                                }}
                                whileTap={{ scale: 0.95 }}
                                onClick={() => navigate('/exhibition')}
                                className="px-8 py-3 bg-gradient-to-r from-purple-600 to-pink-600 rounded-full 
                                         font-bold text-white transition-all duration-300 
                                         hover:from-purple-700 hover:to-pink-700
                                         relative overflow-hidden group"
                            >
                                <span className="relative z-10">Explore Exhibition</span>
                                <motion.div
                                    className="absolute inset-0 bg-gradient-to-r from-purple-800 to-pink-800 opacity-0 
                                             group-hover:opacity-100 transition-opacity duration-300"
                                />
                            </motion.button>
                            <motion.button 
                                whileHover={{ 
                                    scale: 1.05,
                                    boxShadow: "0 0 20px rgba(168, 85, 247, 0.4)"
                                }}
                                whileTap={{ scale: 0.95 }}
                                className="px-8 py-3 border-2 border-black bg-black rounded-full 
                                         font-bold text-white transition-all duration-300
                                         hover:border-purple-500 hover:shadow-lg hover:shadow-purple-500/25
                                         relative overflow-hidden group"
                            >
                                <span className="relative z-10 group-hover:text-white">Learn More</span>
                                <motion.div
                                    className="absolute inset-0 bg-gradient-to-r from-purple-600 to-pink-600 opacity-0 
                                             group-hover:opacity-100 transition-opacity duration-300"
                                />
                            </motion.button>
                        </div>
                    </motion.div>
    
                    <motion.div 
                        initial={{ opacity: 0, scale: 0.9 }}
                        animate={{ opacity: 1, scale: 1 }}
                        className="flex-1"
                    >
                        <div className="relative aspect-square max-w-xl mx-auto">
                            <div className="absolute inset-0 bg-gradient-to-r from-purple-500 to-pink-500 rounded-2xl -rotate-6 blur-sm" />
                            <div className="absolute inset-0 bg-black rounded-2xl">
                                <iframe
                                    src={iframeSources[currentSlide]}
                                    className="w-full h-full rounded-2xl"
                                    frameBorder="0"
                                    allowFullScreen
                                />
                            </div>
                        </div>
                    </motion.div>
                </div>
            </section>
    
            <section className="py-20 bg-black/30 backdrop-blur-sm">
                <div className="container mx-auto px-4">
                    <div className="grid md:grid-cols-3 gap-8">
                        {[
                            {
                                title: "Create",
                                description: "Use p5.js and three.js to bring your digital art to life",
                                icon: "🎨"
                            },
                            {
                                title: "Inscribe",
                                description: "Transform your creations into permanent Bitcoin artifacts",
                                icon: "⛓️"
                            },
                            {
                                title: "Trade",
                                description: "Connect with collectors and fellow artists",
                                icon: "🤝"
                            }
                        ].map((feature, index) => (
                            <motion.div
                                key={index}
                                initial={{ opacity: 0, y: 20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                                transition={{ delay: index * 0.2 }}
                                className="p-8 rounded-2xl bg-white backdrop-blur-sm 
                                         border border-purple-500/20 hover:border-purple-500/40 transition-all duration-300
                                         hover:shadow-lg hover:shadow-purple-500/10"
                            >
                                <div className="text-4xl mb-4">{feature.icon}</div>
                                <h3 className="text-2xl font-bold mb-3 text-black">{feature.title}</h3>
                                <p className="text-black text-lg font-medium">{feature.description}</p>
                            </motion.div>
                        ))}
                    </div>
                </div>
            </section>
    
            <section className="py-20">
                <div className="container mx-auto px-4 max-w-2xl">
                    <motion.div
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        viewport={{ once: true }}
                        className="bg-white backdrop-blur-sm 
                                 border border-purple-500/20 rounded-2xl p-8 shadow-xl"
                    >
                        <h2 className="text-3xl font-bold mb-8 text-center text-black">Get in Touch</h2>
                        <form className="space-y-6">
                            <div>
                                <label className="block text-lg font-bold mb-2 text-black">Name</label>
                                <input
                                    type="text"
                                    className="w-full px-4 py-3 bg-white border border-purple-500/30 rounded-lg 
                                             text-black placeholder-gray-600 focus:outline-none focus:ring-2 
                                             focus:ring-purple-500 focus:border-transparent transition-all duration-200"
                                    placeholder="Your name"
                                />
                            </div>
                            <div>
                                <label className="block text-lg font-bold mb-2 text-black">Email</label>
                                <input
                                    type="email"
                                    className="w-full px-4 py-3 bg-white border border-purple-500/30 rounded-lg 
                                             text-black placeholder-gray-600 focus:outline-none focus:ring-2 
                                             focus:ring-purple-500 focus:border-transparent transition-all duration-200"
                                    placeholder="your@email.com"
                                />
                            </div>
                            <div>
                                <label className="block text-lg font-bold mb-2 text-black">Message</label>
                                <textarea
                                    rows="4"
                                    className="w-full px-4 py-3 bg-white border border-purple-500/30 rounded-lg 
                                             text-black placeholder-gray-600 focus:outline-none focus:ring-2 
                                             focus:ring-purple-500 focus:border-transparent transition-all duration-200"
                                    placeholder="Your message..."
                                />
                            </div>
                            <motion.button
                                whileHover={{ 
                                    scale: 1.02,
                                    boxShadow: "0 0 20px rgba(168, 85, 247, 0.4)"
                                }}
                                whileTap={{ scale: 0.98 }}
                                type="submit"
                                className="w-full py-3 bg-black rounded-lg font-bold text-white 
                                         transition-all duration-300 relative overflow-hidden group"
                            >
                                <span className="relative z-10 group-hover:text-white">Send Message</span>
                                <motion.div
                                    className="absolute inset-0 bg-gradient-to-r from-purple-600 to-pink-600 opacity-0 
                                             group-hover:opacity-100 transition-opacity duration-300"
                                />
                            </motion.button>
                        </form>
                    </motion.div>
                </div>
            </section>
    
            <footer className="py-12 bg-white backdrop-blur-sm border-t border-purple-500/20">
                <div className="container mx-auto px-4 text-center">
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        className="space-y-4"
                    >
                        <h2 className="text-2xl font-bold text-black">Space Scribe Art Exchange</h2>
                        <p className="text-black text-lg font-medium max-w-2xl mx-auto">
                            Discover and acquire unique digital art pieces that open windows to new worlds. 
                            Our curated selection connects you with the cosmos through art.
                        </p>
                        <div className="pt-8 text-black font-medium">
                            <p>&copy; {new Date().getFullYear()} Space Scribe. All rights reserved.</p>
                        </div>
                    </motion.div>
                </div>
            </footer>
        </div>
    );
};

export default Home;