import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Orders.css'; 

const API_BASE_URL = "https://exhibition-service-production.up.railway.app"

const OrderDashboard = () => {
  const [apiKey, setApiKey] = useState('');
  const [status, setStatus] = useState('pending');
  const [sortOrder, setSortOrder] = useState('asc');
  const [itemCount, setItemCount] = useState(16);
  const [receiveAddress, setReceiveAddress] = useState('');
  const [clientId, setClientId] = useState('');
  const [cursor, setCursor] = useState(0); 
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState('');
  const [pendingCollections, setPendingCollections] = useState([]);
  const [approvedCollections, setApprovedCollections] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [editingCollection, setEditingCollection] = useState(null); 

  useEffect(() => {
    if (isAuthenticated) {
      fetchPendingCollections();
      fetchApprovedCollections();
    }
  }, [isAuthenticated]);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${API_BASE_URL}/api/login`, { username, password });

      if (response.data.success) {
        setIsAuthenticated(true);
      } else {
        alert('Invalid credentials. Please try again.');
      }
    } catch (err) {
      console.error('Login failed:', err.message);
      alert('An error occurred during login. Please try again.');
    }
  };

  const fetchOrders = async () => {
    setError('');
    if (!apiKey) {
      setError('Please enter a valid API key.');
      return;
    }

    const encodedReceiveAddress = encodeURIComponent(receiveAddress.trim());

    const params = new URLSearchParams({
      status,
      sort: sortOrder,
      size: itemCount,
      ...(receiveAddress && { receiveAddress: encodedReceiveAddress }),
      ...(clientId && { clientId }),
      cursor,
    });

    try {
      const response = await axios.get(`https://open-api.unisat.io/v2/inscribe/order/list?${params.toString()}`, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${apiKey}`,
        },
      });

      if (response.data && response.data.code === 0) {
        const fetchedOrders = response.data.data.list.map(order => ({
          ...order,
          formattedDate: new Date(order.timestamp).toLocaleString(), // Format the date and time
        }));
        setOrders(fetchedOrders);
      } else {
        setError('Invalid or unexpected API response structure.');
      }
    } catch (err) {
      setError(`Failed to fetch orders: ${err.message || err.response?.data?.msg}`);
      setOrders([]);
    }
  };

  const fetchPendingCollections = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/pending-collections`, {
        auth: {
          username: username,
          password: password,
        },
      });
      setPendingCollections(response.data);
    } catch (err) {
      setError(`Failed to fetch pending collections: ${err.message}`);
    }
  };

  const fetchApprovedCollections = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/artworks`, {
        auth: {
          username: username,
          password: password,
        },
      });
      setApprovedCollections(response.data);
    } catch (err) {
      setError(`Failed to fetch approved collections: ${err.message}`);
    }
  };

  const approveCollection = async (collection) => {
    try {
      await axios.post(`${API_BASE_URL}/api/approve-collection`, { title: collection.title }, {
        auth: {
          username: username,
          password: password,
        },
      });
      alert(`Collection "${collection.title}" has been approved.`);
      setPendingCollections(prev => prev.filter(item => item.title !== collection.title));
      fetchApprovedCollections();
    } catch (err) {
      setError(`Failed to approve collection: ${err.message}`);
    }
  };

  const handleEditCollection = (collection, isApproved) => {
    setEditingCollection({ ...collection, isApproved });
  };

  const handleSaveCollection = async () => {
    const { id, isApproved, ...updatedData } = editingCollection;
    try {
      await axios.put(`${API_BASE_URL}/api/artworks/${id}`, updatedData, {
        auth: {
          username: username,
          password: password,
        },
      });
      alert('Collection updated successfully.');
      setEditingCollection(null);
      if (isApproved) {
        fetchApprovedCollections();
      } else {
        fetchPendingCollections();
      }
    } catch (err) {
      setError(`Failed to update collection: ${err.message}`);
    }
  };

  const updateSupplyAndSold = async (collectionId, newItems, newSold) => {
    try {
      await axios.post(`${API_BASE_URL}/api/artworks/update-supply`, { artworkId: collectionId, items: newItems, sold: newSold }, {
        auth: {
          username: username,
          password: password,
        },
      });
      alert('Supply and sold amount updated successfully.');
      fetchApprovedCollections(); // Refresh the collection data
    } catch (err) {
      setError(`Failed to update supply and sold amount: ${err.message}`);
    }
  };

  const deleteCollection = async (collectionId, isApproved) => {
    try {
      await axios.delete(`${API_BASE_URL}/api/artworks/${collectionId}`, {
        auth: {
          username: username,
          password: password,
        },
      });
      alert('Collection has been deleted.');
      if (isApproved) {
        setApprovedCollections(prev => prev.filter(item => item.id !== collectionId));
      } else {
        setPendingCollections(prev => prev.filter(item => item.id !== collectionId));
      }
    } catch (err) {
      setError(`Failed to delete collection: ${err.message}`);
    }
  };

  const fetchOrderDetails = async (orderId) => {
    setError('');
    try {
      const response = await axios.get(`https://open-api.unisat.io/v2/inscribe/order/${orderId}`, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${apiKey}`,
        },
      });

      if (response.data && response.data.code === 0) {
        const orderDetails = {
          ...response.data.data,
          formattedDate: new Date(response.data.data.timestamp).toLocaleString(), // Format the date and time
        };
        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order.orderId === orderId ? { ...order, details: orderDetails } : order
          )
        );
      } else {
        setError('Invalid or unexpected API response structure.');
      }
    } catch (err) {
      setError(`Failed to fetch order details: ${err.message || err.response?.data?.msg}`);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 via-purple-900 to-black p-6">
        <div className="max-w-7xl mx-auto space-y-6">
            {!isAuthenticated ? (
                <div className="flex justify-center items-center min-h-screen">
                    <form onSubmit={handleLogin} className="bg-white/5 backdrop-blur-sm rounded-xl p-8 w-full max-w-md space-y-4">
                        <h2 className="text-3xl font-bold text-center text-white">Login</h2>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="Username"
                            required
                            className="w-full px-4 py-2 bg-white/10 border border-purple-500/30 rounded-lg
                                     text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500"
                        />
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                            required
                            className="w-full px-4 py-2 bg-white/10 border border-purple-500/30 rounded-lg
                                     text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500"
                        />
                        <button 
                            type="submit"
                            className="w-full py-2 bg-gradient-to-r from-purple-600 to-pink-600 rounded-lg
                                     text-white font-bold hover:shadow-lg hover:shadow-purple-500/25"
                        >
                            Login
                        </button>
                    </form>
                </div>
            ) : (
                <>
                    <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6 space-y-4">
                        <h1 className="text-3xl font-bold text-white">Order Dashboard</h1>
                        
                        {/* API Key and Filters */}
                        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
                            <input
                                type="text"
                                value={apiKey}
                                onChange={(e) => setApiKey(e.target.value)}
                                placeholder="Enter API Key"
                                className="col-span-full px-4 py-2 bg-white/10 border border-purple-500/30 rounded-lg
                                         text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500"
                            />
                            <input
                                type="text"
                                value={receiveAddress}
                                onChange={(e) => setReceiveAddress(e.target.value)}
                                placeholder="Filter by Receive Address (optional)"
                                className="px-4 py-2 bg-white/10 border border-purple-500/30 rounded-lg
                                         text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500"
                            />
                            <input
                                type="text"
                                value={clientId}
                                onChange={(e) => setClientId(e.target.value)}
                                placeholder="Filter by Client ID (optional)"
                                className="px-4 py-2 bg-white/10 border border-purple-500/30 rounded-lg
                                         text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500"
                            />
                            <select 
                                value={status} 
                                onChange={(e) => setStatus(e.target.value)}
                                className="px-4 py-2 bg-white/10 border border-purple-500/30 rounded-lg
                                         text-white focus:outline-none focus:ring-2 focus:ring-purple-500"
                            >
                                <option value="pending">Pending</option>
                                <option value="inscribing">Inscribing</option>
                                <option value="minted">Minted</option>
                                <option value="closed">Closed</option>
                                <option value="refunded">Refunded</option>
                            </select>
                            <select 
                                value={sortOrder} 
                                onChange={(e) => setSortOrder(e.target.value)}
                                className="px-4 py-2 bg-white/10 border border-purple-500/30 rounded-lg
                                         text-white focus:outline-none focus:ring-2 focus:ring-purple-500"
                            >
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                            <input
                                type="number"
                                value={itemCount}
                                onChange={(e) => setItemCount(Number(e.target.value))}
                                placeholder="Number of items"
                                className="px-4 py-2 bg-white/10 border border-purple-500/30 rounded-lg
                                         text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500"
                            />
                            <input
                                type="number"
                                value={cursor}
                                onChange={(e) => setCursor(Number(e.target.value))}
                                placeholder="Start offset"
                                className="px-4 py-2 bg-white/10 border border-purple-500/30 rounded-lg
                                         text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500"
                            />
                        </div>

                        <button 
                            onClick={fetchOrders}
                            className="w-full py-2 bg-gradient-to-r from-purple-600 to-pink-600 rounded-lg
                                     text-white font-bold hover:shadow-lg hover:shadow-purple-500/25"
                        >
                            Fetch Orders
                        </button>

                        {error && (
                            <p className="p-4 bg-red-500/10 border border-red-500/20 rounded-lg text-red-400">
                                {error}
                            </p>
                        )}

                        {/* Orders List */}
                        <div className="space-y-4">
                            {orders.length > 0 ? (
                                orders.map((order, index) => (
                                    <div 
                                        key={order.orderId || index} 
                                        className="bg-white/5 backdrop-blur-sm rounded-xl p-4 space-y-2
                                                 border border-purple-500/20"
                                    >
                                        <div className="grid gap-4 md:grid-cols-2">
                                            <div className="space-y-2">
                                                <p className="flex justify-between">
                                                    <span className="text-purple-300">Order ID:</span>
                                                    <span className="text-white">{order.orderId}</span>
                                                </p>
                                                <p className="flex justify-between">
                                                    <span className="text-purple-300">Status:</span>
                                                    <span className={`px-2 py-1 rounded-full text-sm
                                                        ${order.status === 'minted' ? 'bg-green-500/20 text-green-400' :
                                                          order.status === 'pending' ? 'bg-yellow-500/20 text-yellow-400' :
                                                          order.status === 'inscribing' ? 'bg-blue-500/20 text-blue-400' :
                                                          order.status === 'refunded' ? 'bg-red-500/20 text-red-400' :
                                                          'bg-gray-500/20 text-gray-400'}`}>
                                                        {order.status}
                                                    </span>
                                                </p>
                                                <p className="flex justify-between">
                                                    <span className="text-purple-300">Date:</span>
                                                    <span className="text-white">{order.formattedDate}</span>
                                                </p>
                                                <p className="flex justify-between">
                                                    <span className="text-purple-300">Pay Address:</span>
                                                    <span className="text-white truncate ml-2">{order.payAddress}</span>
                                                </p>
                                                <p className="flex justify-between">
                                                    <span className="text-purple-300">Receive Address:</span>
                                                    <span className="text-white truncate ml-2">{order.receiveAddress}</span>
                                                </p>
                                                <p className="flex justify-between">
                                                    <span className="text-purple-300">Amount:</span>
                                                    <span className="text-white">{order.amount}</span>
                                                </p>
                                                <p className="flex justify-between">
                                                    <span className="text-purple-300">Paid Amount:</span>
                                                    <span className="text-white">{order.paidAmount}</span>
                                                </p>
                                                <p className="flex justify-between">
                                                    <span className="text-purple-300">Output Value:</span>
                                                    <span className="text-white">{order.outputValue}</span>
                                                </p>
                                                <p className="flex justify-between">
                                                    <span className="text-purple-300">Fee Rate:</span>
                                                    <span className="text-white">{order.feeRate}</span>
                                                </p>
                                                <p className="flex justify-between">
                                                    <span className="text-purple-300">Miner Fee:</span>
                                                    <span className="text-white">{order.minerFee}</span>
                                                </p>
                                                <p className="flex justify-between">
                                                    <span className="text-purple-300">Service Fee:</span>
                                                    <span className="text-white">{order.serviceFee}</span>
                                                </p>
                                                <p className="flex justify-between">
                                                    <span className="text-purple-300">Developer Fee:</span>
                                                    <span className="text-white">{order.devFee}</span>
                                                </p>
                                            </div>

                                            {/* Files Section */}
                                            {order.files && order.files.map((file, fileIndex) => (
                                                <div key={fileIndex} className="p-4 bg-white/5 rounded-lg space-y-2">
                                                    <p className="flex justify-between">
                                                        <span className="text-purple-300">File:</span>
                                                        <span className="text-white">{file.filename}</span>
                                                    </p>
                                                    <p className="flex justify-between">
                                                        <span className="text-purple-300">Inscription ID:</span>
                                                        <span className="text-white truncate ml-2">{file.inscriptionId}</span>
                                                    </p>
                                                    <p className="flex justify-between">
                                                        <span className="text-purple-300">File Status:</span>
                                                        <span className="text-white">{file.status}</span>
                                                    </p>
                                                </div>
                                            ))}

                                            <div className="space-y-2">
                                                <p className="flex justify-between">
                                                    <span className="text-purple-300">Refund Transaction ID:</span>
                                                    <span className="text-white truncate ml-2">{order.refundTxid}</span>
                                                </p>
                                                <p className="flex justify-between">
                                                    <span className="text-purple-300">Refund Amount:</span>
                                                    <span className="text-white">{order.refundAmount}</span>
                                                </p>
                                                <p className="flex justify-between">
                                                    <span className="text-purple-300">Refund Fee Rate:</span>
                                                    <span className="text-white">{order.refundFeeRate}</span>
                                                </p>
                                            </div>

                                            <button 
                                                onClick={() => fetchOrderDetails(order.orderId)}
                                                className="col-span-full py-2 bg-purple-500/20 rounded-lg text-white
                                                         hover:bg-purple-500/30 transition-all duration-200"
                                            >
                                                Fetch Details
                                            </button>

                                            {order.details && (
                                                <div className="col-span-full space-y-4">
                                                    {order.details.files && order.details.files.map((file, fileIndex) => (
                                                        <div key={fileIndex} className="p-4 bg-white/5 rounded-lg space-y-2">
                                                            <p className="flex justify-between">
                                                                <span className="text-purple-300">File Name:</span>
                                                                <span className="text-white">{file.filename}</span>
                                                            </p>
                                                            <p className="flex justify-between">
                                                                <span className="text-purple-300">Inscription ID:</span>
                                                                <span className="text-white truncate ml-2">{file.inscriptionId}</span>
                                                            </p>
                                                            <p className="flex justify-between">
                                                                <span className="text-purple-300">File Status:</span>
                                                                <span className="text-white">{file.status}</span>
                                                            </p>
                                                        </div>
                                                    ))}
                                                    <p className="flex justify-between">
                                                        <span className="text-purple-300">Receiver Address:</span>
                                                        <span className="text-white truncate ml-2">{order.details.receiveAddress}</span>
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p className="text-center py-10 text-gray-400">No orders to display.</p>
                            )}
                        </div>
                    </div>

                    {/* Pending Collections Management */}
                    <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6 mt-8">
                        <h2 className="text-2xl font-bold text-white mb-6">Pending Collections</h2>
                        <div className="space-y-4">
                            {pendingCollections.length > 0 ? (
                                pendingCollections.map((collection, index) => (
                                    <div key={index} className="bg-white/5 backdrop-blur-sm rounded-xl p-4 space-y-4
                                                                  border border-purple-500/20">
                                        {editingCollection && editingCollection.id === collection.id ? (
                                            <div className="space-y-4">
                                                <input
                                                    type="text"
                                                    value={editingCollection.title}
                                                    onChange={(e) =>
                                                        setEditingCollection({ ...editingCollection, title: e.target.value })
                                                    }
                                                    placeholder="Title"
                                                    className="w-full px-4 py-2 bg-white/10 border border-purple-500/30 
                                                                 rounded-lg text-white placeholder-gray-400"
                                                />
                                                <input
                                                    type="text"
                                                    value={editingCollection.description}
                                                    onChange={(e) =>
                                                        setEditingCollection({ ...editingCollection, description: e.target.value })
                                                    }
                                                    placeholder="Description"
                                                    className="w-full px-4 py-2 bg-white/10 border border-purple-500/30 
                                                                 rounded-lg text-white placeholder-gray-400"
                                                />
                                                <input
                                                    type="text"
                                                    value={editingCollection.price}
                                                    onChange={(e) =>
                                                        setEditingCollection({ ...editingCollection, price: e.target.value })
                                                    }
                                                    placeholder="Price"
                                                    className="w-full px-4 py-2 bg-white/10 border border-purple-500/30 
                                                                 rounded-lg text-white placeholder-gray-400"
                                                />
                                                <input
                                                    type="text"
                                                    value={editingCollection.artist}
                                                    onChange={(e) =>
                                                        setEditingCollection({ ...editingCollection, artist: e.target.value })
                                                    }
                                                    placeholder="Artist"
                                                    className="w-full px-4 py-2 bg-white/10 border border-purple-500/30 
                                                                 rounded-lg text-white placeholder-gray-400"
                                                />
                                                <input
                                                    type="text"
                                                    value={editingCollection.iframeSrc}
                                                    onChange={(e) =>
                                                        setEditingCollection({ ...editingCollection, iframeSrc: e.target.value })
                                                    }
                                                    placeholder="Iframe Source"
                                                    className="w-full px-4 py-2 bg-white/10 border border-purple-500/30 
                                                                 rounded-lg text-white placeholder-gray-400"
                                                />
                                                <input
                                                    type="text"
                                                    value={editingCollection.sellerAddress}
                                                    onChange={(e) =>
                                                        setEditingCollection({ ...editingCollection, sellerAddress: e.target.value })
                                                    }
                                                    placeholder="Seller Address"
                                                    className="w-full px-4 py-2 bg-white/10 border border-purple-500/30 
                                                                 rounded-lg text-white placeholder-gray-400"
                                                />
                                                <div className="flex gap-2">
                                                    <button 
                                                        onClick={handleSaveCollection}
                                                        className="flex-1 py-2 bg-gradient-to-r from-purple-600 to-pink-600 
                                                                     rounded-lg text-white font-bold hover:shadow-lg 
                                                                     hover:shadow-purple-500/25"
                                                    >
                                                        Save
                                                    </button>
                                                    <button 
                                                        onClick={() => setEditingCollection(null)}
                                                        className="flex-1 py-2 bg-white/10 rounded-lg text-white 
                                                                     font-bold hover:bg-white/20"
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="space-y-2">
                                                <p className="flex justify-between">
                                                    <span className="text-purple-300">Title:</span>
                                                    <span className="text-white">{collection.title}</span>
                                                </p>
                                                <p className="flex justify-between">
                                                    <span className="text-purple-300">Description:</span>
                                                    <span className="text-white">{collection.description}</span>
                                                </p>
                                                <p className="flex justify-between">
                                                    <span className="text-purple-300">Price:</span>
                                                    <span className="text-white">{collection.price}</span>
                                                </p>
                                                <p className="flex justify-between">
                                                    <span className="text-purple-300">Artist:</span>
                                                    <span className="text-white">{collection.artist}</span>
                                                </p>
                                                <p className="flex justify-between">
                                                    <span className="text-purple-300">Iframe Source:</span>
                                                    <span className="text-white truncate ml-2">{collection.iframeSrc}</span>
                                                </p>
                                                <p className="flex justify-between">
                                                    <span className="text-purple-300">Seller Address:</span>
                                                    <span className="text-white truncate ml-2">{collection.sellerAddress}</span>
                                                </p>
                                                <div className="flex gap-2 mt-4">
                                                    <button 
                                                        onClick={() => approveCollection(collection)}
                                                        className="flex-1 py-2 bg-green-500/20 text-green-400 rounded-lg
                                                                     hover:bg-green-500/30"
                                                    >
                                                        Approve
                                                    </button>
                                                    <button 
                                                        onClick={() => handleEditCollection(collection, false)}
                                                        className="flex-1 py-2 bg-purple-500/20 text-white rounded-lg
                                                                     hover:bg-purple-500/30"
                                                    >
                                                        Edit
                                                    </button>
                                                    <button 
                                                        onClick={() => deleteCollection(collection.id, false)}
                                                        className="flex-1 py-2 bg-red-500/20 text-red-400 rounded-lg
                                                                     hover:bg-red-500/30"
                                                    >
                                                        Delete
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <p className="text-center py-10 text-gray-400">No collections waiting for approval.</p>
                            )}
                        </div>
                    </div>

                    {/* Approved Collections */}
                    <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6 mt-8">
                        <h2 className="text-2xl font-bold text-white mb-6">Approved Collections</h2>
                        <div className="space-y-4">
                            {approvedCollections.length > 0 ? (
                                approvedCollections.map((collection, index) => (
                                    <div key={index} className="bg-white/5 backdrop-blur-sm rounded-xl p-4 space-y-4
                                                                  border border-purple-500/20">
                                        {editingCollection && editingCollection.id === collection.id ? (
                                            <div className="space-y-4">
                                                <input
                                                    type="text"
                                                    value={editingCollection.title}
                                                    onChange={(e) =>
                                                        setEditingCollection({ ...editingCollection, title: e.target.value })
                                                    }
                                                    placeholder="Title"
                                                    className="w-full px-4 py-2 bg-white/10 border border-purple-500/30 
                                                                 rounded-lg text-white placeholder-gray-400"
                                                />
                                                <input
                                                    type="text"
                                                    value={editingCollection.description}
                                                    onChange={(e) =>
                                                        setEditingCollection({ ...editingCollection, description: e.target.value })
                                                    }
                                                    placeholder="Description"
                                                    className="w-full px-4 py-2 bg-white/10 border border-purple-500/30 
                                                                 rounded-lg text-white placeholder-gray-400"
                                                />
                                                <input
                                                    type="text"
                                                    value={editingCollection.price}
                                                    onChange={(e) =>
                                                        setEditingCollection({ ...editingCollection, price: e.target.value })
                                                    }
                                                    placeholder="Price"
                                                    className="w-full px-4 py-2 bg-white/10 border border-purple-500/30 
                                                                 rounded-lg text-white placeholder-gray-400"
                                                />
                                                <input
                                                    type="text"
                                                    value={editingCollection.artist}
                                                    onChange={(e) =>
                                                        setEditingCollection({ ...editingCollection, artist: e.target.value })
                                                    }
                                                    placeholder="Artist"
                                                    className="w-full px-4 py-2 bg-white/10 border border-purple-500/30 
                                                                 rounded-lg text-white placeholder-gray-400"
                                                />
                                                <input
                                                    type="text"
                                                    value={editingCollection.iframeSrc}
                                                    onChange={(e) =>
                                                        setEditingCollection({ ...editingCollection, iframeSrc: e.target.value })
                                                    }
                                                    placeholder="Iframe Source"
                                                    className="w-full px-4 py-2 bg-white/10 border border-purple-500/30 
                                                                 rounded-lg text-white placeholder-gray-400"
                                                />
                                                <input
                                                    type="text"
                                                    value={editingCollection.sellerAddress}
                                                    onChange={(e) =>
                                                        setEditingCollection({ ...editingCollection, sellerAddress: e.target.value })
                                                    }
                                                    placeholder="Seller Address"
                                                    className="w-full px-4 py-2 bg-white/10 border border-purple-500/30 
                                                                 rounded-lg text-white placeholder-gray-400"
                                                />
                                                <div className="grid grid-cols-2 gap-4">
                                                    <input
                                                        type="number"
                                                        value={editingCollection.items}
                                                        onChange={(e) =>
                                                            setEditingCollection({ ...editingCollection, items: e.target.value })
                                                        }
                                                        placeholder="Supply"
                                                        className="w-full px-4 py-2 bg-white/10 border border-purple-500/30 
                                                                     rounded-lg text-white placeholder-gray-400"
                                                    />
                                                    <input
                                                        type="number"
                                                        value={editingCollection.sold}
                                                        onChange={(e) =>
                                                            setEditingCollection({ ...editingCollection, sold: e.target.value })
                                                        }
                                                        placeholder="Sold"
                                                        className="w-full px-4 py-2 bg-white/10 border border-purple-500/30 
                                                                     rounded-lg text-white placeholder-gray-400"
                                                    />
                                                </div>
                                                <div className="flex gap-2">
                                                    <button 
                                                        onClick={() => updateSupplyAndSold(
                                                            collection.id, 
                                                            editingCollection.items, 
                                                            editingCollection.sold
                                                        )}
                                                        className="flex-1 py-2 bg-blue-500/20 text-blue-400 rounded-lg
                                                                     hover:bg-blue-500/30"
                                                    >
                                                        Update Supply and Sold
                                                    </button>
                                                </div>
                                                <div className="flex gap-2">
                                                    <button 
                                                        onClick={handleSaveCollection}
                                                        className="flex-1 py-2 bg-gradient-to-r from-purple-600 to-pink-600 
                                                                     rounded-lg text-white font-bold hover:shadow-lg 
                                                                     hover:shadow-purple-500/25"
                                                    >
                                                        Save
                                                    </button>
                                                    <button 
                                                        onClick={() => setEditingCollection(null)}
                                                        className="flex-1 py-2 bg-white/10 rounded-lg text-white 
                                                                     font-bold hover:bg-white/20"
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="space-y-2">
                                                <p className="flex justify-between">
                                                    <span className="text-purple-300">Title:</span>
                                                    <span className="text-white">{collection.title}</span>
                                                </p>
                                                <p className="flex justify-between">
                                                    <span className="text-purple-300">Description:</span>
                                                    <span className="text-white">{collection.description}</span>
                                                </p>
                                                <p className="flex justify-between">
                                                    <span className="text-purple-300">Price:</span>
                                                    <span className="text-white">{collection.price}</span>
                                                </p>
                                                <p className="flex justify-between">
                                                    <span className="text-purple-300">Artist:</span>
                                                    <span className="text-white">{collection.artist}</span>
                                                </p>
                                                <p className="flex justify-between">
                                                    <span className="text-purple-300">Supply:</span>
                                                    <span className="text-white">{collection.items}</span>
                                                </p>
                                                <p className="flex justify-between">
                                                    <span className="text-purple-300">Sold:</span>
                                                    <span className="text-white">{collection.sold}</span>
                                                </p>
                                                <div className="flex gap-2 mt-4">
                                                    <button 
                                                        onClick={() => handleEditCollection(collection, true)}
                                                        className="flex-1 py-2 bg-purple-500/20 text-white rounded-lg
                                                                     hover:bg-purple-500/30"
                                                    >
                                                        Edit
                                                    </button>
                                                    <button 
                                                        onClick={() => deleteCollection(collection.id, true)}
                                                        className="flex-1 py-2 bg-red-500/20 text-red-400 rounded-lg
                                                                     hover:bg-red-500/30"
                                                    >
                                                        Delete
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <p className="text-center py-10 text-gray-400">No approved collections available.</p>
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    </div>
);
};

export default OrderDashboard;