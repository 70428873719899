import React, { useState, useEffect } from 'react';
import { getInscriptions } from '../api/walletApi';
import { motion } from 'framer-motion';

const TransactionHistory = () => {
    const [history, setHistory] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [inputItemsPerPage, setInputItemsPerPage] = useState(20);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchHistory(itemsPerPage);
    }, [itemsPerPage]);

    const fetchHistory = async (items) => {
        setIsLoading(true);
        try {
            const fetchedHistory = await getInscriptions(0, items);
            setHistory(Array.isArray(fetchedHistory) ? fetchedHistory : []);
        } catch (e) {
            console.error('Error fetching transaction history:', e);
            setHistory([]);
        } finally {
            setIsLoading(false);
        }
    };

    const handleItemsPerPageChange = (e) => {
        setInputItemsPerPage(Number(e.target.value));
    };

    const handleSubmit = () => {
        setItemsPerPage(inputItemsPerPage);
    };

    const handleClick = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const truncateText = (text, maxLength = 16) => {
        if (!text) return '';
        if (text.length <= maxLength) return text;
        return `${text.slice(0, maxLength)}...`;
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-gray-900 via-purple-900 to-black p-6">
            <div className="max-w-7xl mx-auto space-y-6">
                {/* Header */}
                <div className="flex justify-between items-center bg-white/5 backdrop-blur-sm rounded-xl p-4">
                    <h2 className="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-400">
                        Transaction History
                    </h2>
                    
                    <div className="flex items-center gap-4 bg-white/10 rounded-lg p-2">
                        <label htmlFor="itemsPerPage" className="text-white text-sm whitespace-nowrap">
                            Transactions per page:
                        </label>
                        <input
                            type="number"
                            id="itemsPerPage"
                            value={inputItemsPerPage}
                            onChange={handleItemsPerPageChange}
                            min="1"
                            max="100"
                            className="w-20 px-3 py-1 bg-white/10 border border-purple-500/30 rounded-lg
                                     text-white focus:outline-none focus:ring-2 focus:ring-purple-500"
                        />
                        <motion.button
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.98 }}
                            onClick={handleSubmit}
                            className="px-4 py-1 bg-gradient-to-r from-purple-600 to-pink-600 rounded-lg
                                     text-white font-medium hover:shadow-lg hover:shadow-purple-500/25
                                     transition-all duration-200"
                        >
                            Update
                        </motion.button>
                    </div>
                </div>

                {/* Transaction List */}
                {isLoading ? (
                    <div className="flex justify-center items-center py-20">
                        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-500"/>
                    </div>
                ) : history.length > 0 ? (
                    <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
                        {history.map((transaction, index) => (
                            <motion.div
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: index * 0.1 }}
                                key={transaction.inscriptionId || index}
                                onClick={() => handleClick(transaction.content)}
                                className="bg-white/5 backdrop-blur-sm rounded-xl p-4 cursor-pointer
                                         hover:bg-white/10 transition-all duration-200 border border-purple-500/20
                                         hover:border-purple-500/40 group overflow-hidden"
                            >
                                <div className="space-y-2">
                                    <div className="flex items-center">
                                        <span className="text-purple-300 text-sm font-medium min-w-[100px]">
                                            Inscription ID:
                                        </span>
                                        <span className="text-white text-sm truncate" title={transaction.inscriptionId}>
                                            {truncateText(transaction.inscriptionId, 24)}
                                        </span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="text-purple-300 text-sm font-medium min-w-[100px]">
                                            Address:
                                        </span>
                                        <span className="text-white text-sm truncate" title={transaction.address}>
                                            {truncateText(transaction.address, 24)}
                                        </span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="text-purple-300 text-sm font-medium min-w-[100px]">
                                            Output Value:
                                        </span>
                                        <span className="text-white text-sm">
                                            {transaction.outputValue}
                                        </span>
                                    </div>
                                    {transaction.content && (
                                        <div className="mt-4 rounded-lg overflow-hidden bg-black/30 aspect-square">
                                            <iframe
                                                src={transaction.content}
                                                title="Transaction Content"
                                                sandbox="allow-scripts allow-same-origin"
                                                className="w-full h-full"
                                                loading="lazy"
                                            />
                                        </div>
                                    )}
                                </div>
                            </motion.div>
                        ))}
                    </div>
                ) : (
                    <div className="text-center py-20">
                        <p className="text-gray-400 text-lg">No transactions found.</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TransactionHistory;