import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PurchaseSuccessModal from './PurchaseSuccessModal';
import ConfirmPurchaseModal from './ConfirmPurchaseModal';
import './Exhibition.css';
import './ThumbnailStyles.css';

const Exhibition = () => {
    const [artworks, setArtworks] = useState([]);
    const [collections, setCollections] = useState([]);
    const [selectedArtwork, setSelectedArtwork] = useState(null);
    const [walletAddress, setWalletAddress] = useState('');
    const [userSelectedFeeRate, setUserSelectedFeeRate] = useState(10);
    const [recommendedFees, setRecommendedFees] = useState({});
    const [isConfirming, setIsConfirming] = useState(false);
    const [isTransactionInProgress, setIsTransactionInProgress] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [transactionId, setTransactionId] = useState('');
    const [showCustomConfirmModal, setShowCustomConfirmModal] = useState(false);
    const [confirmationDetails, setConfirmationDetails] = useState({});
    const [showRequestModal, setShowRequestModal] = useState(false);
    const [selectedCollection, setSelectedCollection] = useState(null);
    const [showCreateCollectionModal, setShowCreateCollectionModal] = useState(false);
    const [collectionRequest, setCollectionRequest] = useState({
        title: '',
        description: '',
        price: '',
        artist: '',
        iframeSrc: '',
        sellerAddress: '',
        items: '',
        inscriptions: ['']
    });
    useEffect(() => {
        const fetchArtworksAndFees = async () => {
            try {
                const artworksResponse = await axios.get('https://exhibition-service-production.up.railway.app/api/artworks');
                if (Array.isArray(artworksResponse.data)) {
                    setArtworks(artworksResponse.data);
                    const groupedCollections = artworksResponse.data.reduce((acc, art) => {
                        if (!acc.some(col => col.collectionTitle === art.collectionTitle)) {
                            acc.unshift({
                                collectionTitle: art.collectionTitle,
                                iframeSrc: art.iframeSrc,
                                description: art.description,
                                price: art.price,
                                artist: art.artist,
                                xHandle: art.xHandle
                            });
                        }
                        return acc;
                    }, []);
                    setCollections(groupedCollections);
                } else {
                    console.error('Received data is not an array:', artworksResponse.data);
                }

                const feesResponse = await axios.get('https://mempool.space/api/v1/fees/recommended');
                setRecommendedFees(feesResponse.data);
                setUserSelectedFeeRate(feesResponse.data.halfHourFee);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchArtworksAndFees();
    }, []);

    const handleRequestToList = async () => {
        try {
            if (!collectionRequest.items || isNaN(collectionRequest.items)) {
                alert('Please enter a valid number for the supply (items).');
                return;
            }

            await axios.post('https://exhibition-service-production.up.railway.app/api/request-listing', {
                ...collectionRequest,
                iframeSrc: `https://ordinals.com/preview/${collectionRequest.inscriptions[0]}`
            });
            setShowRequestModal(true);
            setCollectionRequest({
                title: '',
                description: '',
                price: '',
                artist: '',
                iframeSrc: '',
                sellerAddress: '',
                items: '',
                inscriptions: ['']
            });
            setShowCreateCollectionModal(false);
        } catch (error) {
            console.error("Error submitting collection request:", error);
            alert('An error occurred while submitting your request.');
        }
    };

    const handleBuy = (artwork) => {
        if (!isTransactionInProgress && (artwork.items - artwork.sold > 0)) {
            setSelectedArtwork(artwork);
            setIsConfirming(true);
        }
    };

    const handleConfirmPurchase = async () => {
        if (isTransactionInProgress) {
            console.log('Transaction already in progress - Ignoring additional request');
            return;
        }

        if (!selectedArtwork || !walletAddress) {
            alert('Please connect your wallet and select an artwork.');
            return;
        }

        setIsTransactionInProgress(true);
        const feeRate = parseInt(userSelectedFeeRate, 10);

        try {
            const purchaseData = {
                artworkId: selectedArtwork.id,
                userSelectedFeeRate: feeRate,
                userSelectedReceiverAddress: walletAddress,
            };

            const response = await axios.post('https://exhibition-service-production.up.railway.app/api/purchase', purchaseData);
            const result = response.data;

            setConfirmationDetails({
                payAddressAmount: result.payAddressAmount,
                payAddress: result.payAddress,
                receiverAddress: result.receiverAddress,
                feeRate: userSelectedFeeRate,
            });
            setShowCustomConfirmModal(true);
        } catch (error) {
            console.error("Error during the purchase request:", error);
            alert('An error occurred while processing your purchase.');
        } finally {
            setIsTransactionInProgress(false);
            setIsConfirming(false);
        }
    };
    const handlePasteAddress = async () => {
        try {
            const text = await navigator.clipboard.readText();
            setWalletAddress(text);
        } catch (error) {
            console.error('Failed to read clipboard contents:', error);
        }
    };

    const handleTransactionConfirmation = async () => {
        setShowCustomConfirmModal(false);

        if (window.unisat) {
            try {
                const txId = await window.unisat.sendBitcoin(
                    confirmationDetails.payAddress,
                    confirmationDetails.payAddressAmount,
                    { feeRate: confirmationDetails.feeRate }
                );

                if (txId) {
                    setTransactionId(txId);
                    const newSoldCount = selectedArtwork.sold + 1;
                    setArtworks((prevArtworks) => prevArtworks.map((artwork) => {
                        if (artwork.id === selectedArtwork.id) {
                            return { ...artwork, sold: newSoldCount };
                        }
                        return artwork;
                    }));

                    await axios.post('https://exhibition-service-production.up.railway.app/api/artworks/update-supply', {
                        artworkId: selectedArtwork.id,
                        sold: newSoldCount
                    });

                    setShowSuccessModal(true);
                }
            } catch (error) {
                console.error("Error during the payment transaction:", error);
                alert('An error occurred while processing your payment.');
            }
        }
    };

    const addNewInscriptionField = () => {
        setCollectionRequest(prevRequest => ({
            ...prevRequest,
            inscriptions: [...prevRequest.inscriptions, '']
        }));
    };

    const handleInscriptionChange = (index, value) => {
        setCollectionRequest(prevRequest => {
            const newInscriptions = [...prevRequest.inscriptions];
            newInscriptions[index] = value;
            return { ...prevRequest, inscriptions: newInscriptions };
        });
    };

    const handleViewCollection = (collection) => {
        setSelectedCollection(collection);
    };

    const handleBackToThumbnails = () => {
        setSelectedCollection(null);
    };

    return (
        <div className="min-h-screen bg-gray-50">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                <div className="flex justify-between items-center mb-8">
                    <h1 className="text-4xl font-bold text-gray-900">Art Gallery</h1>
                    <button
                        onClick={() => setShowCreateCollectionModal(true)}
                        className="bg-purple-600 text-white px-6 py-2 rounded-lg font-semibold hover:bg-purple-700 transition-colors"
                    >
                        Create Collection
                    </button>
                </div>
                {!selectedCollection ? (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {collections.map((collection, index) => (
                            <div 
                                key={index}
                                className="bg-white rounded-xl shadow-lg overflow-hidden transform transition duration-300 hover:scale-105"
                            >
                                <div className="relative aspect-square">
                                    <iframe
                                        src={collection.iframeSrc}
                                        className="absolute inset-0 w-full h-full"
                                        sandbox="allow-scripts"
                                        loading="lazy"
                                    />
                                </div>
                                <div className="p-6 space-y-4">
                                    <h3 className="text-2xl font-bold text-gray-800">{collection.collectionTitle}</h3>
                                    <p className="text-gray-600 line-clamp-2">{collection.description}</p>
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <p className="text-sm text-gray-500">By {collection.artist}</p>
                                            <p className="text-lg font-semibold text-purple-600">{collection.price} BTC</p>
                                        </div>
                                        <button
                                            onClick={() => handleViewCollection(collection)}
                                            className="px-6 py-2 bg-purple-600 text-white rounded-lg font-semibold hover:bg-purple-700 transition-colors"
                                        >
                                            View Collection
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="space-y-8">
                        <div className="flex items-center justify-between">
                            <h2 className="text-3xl font-bold text-gray-900">{selectedCollection.collectionTitle}</h2>
                            <button
                                onClick={handleBackToThumbnails}
                                className="px-6 py-2 bg-gray-100 text-gray-700 rounded-lg font-semibold hover:bg-gray-200 transition-colors"
                            >
                                Back to Collections
                            </button>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                            {artworks
                                .filter(art => art.collectionTitle === selectedCollection.collectionTitle)
                                .map((artwork) => (
                                    <div key={artwork.id} className="bg-white rounded-xl shadow-lg overflow-hidden">
                                        <div className="relative aspect-square">
                                            <iframe
                                                src={artwork.iframeSrc}
                                                className="absolute inset-0 w-full h-full"
                                                sandbox="allow-scripts"
                                                loading="lazy"
                                            />
                                        </div>
                                        <div className="p-6 space-y-4">
                                            <div className="flex justify-between items-start">
                                                <div>
                                                    <h3 className="text-xl font-bold text-gray-800">{artwork.title}</h3>
                                                    <p className="text-sm text-gray-500">#{artwork.inscriptionNumber}</p>
                                                </div>
                                                <span className="px-3 py-1 bg-purple-100 text-purple-600 rounded-full text-sm">
                                                    {artwork.items - artwork.sold} left
                                                </span>
                                            </div>
                                            <p className="text-gray-600">{artwork.description}</p>
                                            <div className="flex justify-between items-center pt-4">
                                                <p className="text-lg font-semibold text-purple-600">{artwork.price} BTC</p>
                                                <button
                                                    onClick={() => handleBuy(artwork)}
                                                    disabled={isTransactionInProgress || artwork.sold >= artwork.items}
                                                    className={`px-6 py-2 rounded-lg font-semibold transition-colors ${
                                                        artwork.sold < artwork.items 
                                                        ? 'bg-purple-600 hover:bg-purple-700 text-white'
                                                        : 'bg-gray-300 text-gray-600 cursor-not-allowed'
                                                    }`}
                                                >
                                                    {artwork.sold < artwork.items ? 'Buy Now' : 'Sold Out'}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                )}
                                {/* Purchase Confirmation Modal */}
                                {isConfirming && selectedArtwork && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
                        <div className="bg-white rounded-2xl p-8 max-w-md w-full">
                            <h2 className="text-2xl font-bold text-gray-800 mb-6">Confirm Purchase</h2>
                            <div className="space-y-4">
                                <div>
                                    <p className="text-gray-600">Artwork</p>
                                    <p className="text-lg font-semibold">{selectedArtwork.title}</p>
                                </div>
                                <div>
                                    <p className="text-gray-600">Total Fee</p>
                                    <p className="text-lg font-semibold">{selectedArtwork.price} BTC</p>
                                </div>
                                <div className="space-y-2">
                                    <label className="block text-gray-600">Receiver Address</label>
                                    <div className="flex gap-2">
                                        <input
                                            type="text"
                                            value={walletAddress}
                                            onChange={e => setWalletAddress(e.target.value)}
                                            className="flex-1 border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-purple-500"
                                        />
                                        <button
                                            onClick={handlePasteAddress}
                                            className="px-4 py-2 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors"
                                        >
                                            Paste
                                        </button>
                                    </div>
                                </div>
                                <div className="space-y-2">
                                    <label className="block text-gray-600">Fee Rate (sat/vB)</label>
                                    <input
                                        type="number"
                                        value={userSelectedFeeRate}
                                        onChange={e => setUserSelectedFeeRate(e.target.value)}
                                        min="1"
                                        className="w-full border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-purple-500"
                                    />
                                    {recommendedFees.fastestFee && (
                                        <div className="mt-2 text-sm text-gray-500 space-y-1">
                                            <p>⚡️ Fastest: {recommendedFees.fastestFee} sat/vB</p>
                                            <p>🕒 Half Hour: {recommendedFees.halfHourFee} sat/vB</p>
                                            <p>🕐 Hour: {recommendedFees.hourFee} sat/vB</p>
                                        </div>
                                    )}
                                </div>
                                <div className="flex gap-4 pt-4">
                                    <button
                                        onClick={handleConfirmPurchase}
                                        disabled={isTransactionInProgress}
                                        className="flex-1 bg-purple-600 text-white py-3 rounded-lg font-semibold hover:bg-purple-700 transition-colors disabled:bg-gray-300"
                                    >
                                        Confirm Purchase
                                    </button>
                                    <button
                                        onClick={() => setIsConfirming(false)}
                                        className="flex-1 bg-gray-100 text-gray-700 py-3 rounded-lg font-semibold hover:bg-gray-200 transition-colors"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {/* Create Collection Modal */}
                {showCreateCollectionModal && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
                        <div className="bg-white rounded-2xl p-8 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
                            <h2 className="text-2xl font-bold text-gray-800 mb-6">Create a New Collection</h2>
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                handleRequestToList();
                            }} className="space-y-4">
                                                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div>
                                        <label className="block text-gray-700 font-medium mb-2">Title</label>
                                        <input
                                            type="text"
                                            value={collectionRequest.title}
                                            onChange={e => setCollectionRequest({
                                                ...collectionRequest,
                                                title: e.target.value,
                                            })}
                                            className="w-full border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-purple-500"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-gray-700 font-medium mb-2">Artist</label>
                                        <input
                                            type="text"
                                            value={collectionRequest.artist}
                                            onChange={e => setCollectionRequest({
                                                ...collectionRequest,
                                                artist: e.target.value,
                                            })}
                                            className="w-full border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-purple-500"
                                            required
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label className="block text-gray-700 font-medium mb-2">Description</label>
                                    <textarea
                                        value={collectionRequest.description}
                                        onChange={e => setCollectionRequest({
                                            ...collectionRequest,
                                            description: e.target.value,
                                        })}
                                        className="w-full border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-purple-500 h-32"
                                        required
                                    />
                                </div>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div>
                                        <label className="block text-gray-700 font-medium mb-2">Price (BTC)</label>
                                        <input
                                            type="text"
                                            value={collectionRequest.price}
                                            onChange={e => setCollectionRequest({
                                                ...collectionRequest,
                                                price: e.target.value,
                                            })}
                                            className="w-full border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-purple-500"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-gray-700 font-medium mb-2">Supply (items)</label>
                                        <input
                                            type="number"
                                            value={collectionRequest.items}
                                            onChange={e => setCollectionRequest({
                                                ...collectionRequest,
                                                items: e.target.value,
                                            })}
                                            className="w-full border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-purple-500"
                                            required
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label className="block text-gray-700 font-medium mb-2">Seller Address</label>
                                    <input
                                        type="text"
                                        value={collectionRequest.sellerAddress}
                                        onChange={e => setCollectionRequest({
                                            ...collectionRequest,
                                            sellerAddress: e.target.value,
                                        })}
                                        className="w-full border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-purple-500"
                                        required
                                    />
                                </div>

                                <div className="space-y-4">
                                    <label className="block text-gray-700 font-medium">Inscriptions</label>
                                    {collectionRequest.inscriptions.map((inscription, index) => (
                                        <input
                                            key={index}
                                            type="text"
                                            placeholder={`Inscription ID #${index + 1}`}
                                            value={inscription}
                                            onChange={e => handleInscriptionChange(index, e.target.value)}
                                            className="w-full border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-purple-500"
                                            required
                                        />
                                    ))}
                                    <button
                                        type="button"
                                        onClick={addNewInscriptionField}
                                        className="text-purple-600 hover:text-purple-700 font-medium"
                                    >
                                        + Add Another Inscription ID
                                    </button>
                                </div>

                                <div className="flex gap-4 pt-6">
                                    <button
                                        type="submit"
                                        className="flex-1 bg-purple-600 text-white py-3 rounded-lg font-semibold hover:bg-purple-700 transition-colors"
                                    >
                                        Submit Collection
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => setShowCreateCollectionModal(false)}
                                        className="flex-1 bg-gray-100 text-gray-700 py-3 rounded-lg font-semibold hover:bg-gray-200 transition-colors"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                )}

                <PurchaseSuccessModal
                    isOpen={showSuccessModal}
                    transactionId={transactionId}
                    artworkSrc={selectedArtwork ? selectedArtwork.iframeSrc : ''}
                    onClose={() => setShowSuccessModal(false)}
                />

                <ConfirmPurchaseModal
                    isOpen={showCustomConfirmModal}
                    onConfirm={handleTransactionConfirmation}
                    onCancel={() => setShowCustomConfirmModal(false)}
                    paymentDetails={confirmationDetails}
                />

                {showRequestModal && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
                        <div className="bg-white rounded-2xl p-8 max-w-md w-full text-center">
                            <p className="text-xl text-gray-800 mb-6">
                                Your collection request has been submitted and is awaiting approval.
                            </p>
                            <button
                                onClick={() => setShowRequestModal(false)}
                                className="bg-purple-600 text-white px-8 py-3 rounded-lg font-semibold hover:bg-purple-700 transition-colors"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Exhibition;