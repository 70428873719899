import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import './PMT.css';
import ConfirmPurchaseModal from './ConfirmPurchaseModal';
import PurchaseSuccessModal from './PurchaseSuccessModal';
import WalletConnector from './WalletConnector'; // Import WalletConnector

const API_BASE_URL = 'https://serverpmt-production.up.railway.app';
const socket = io(API_BASE_URL);

const PMT = () => {
    const [selectedBlock, setSelectedBlock] = useState(null);
    const [walletAddress, setWalletAddress] = useState('');
    const [userSelectedFeeRate, setUserSelectedFeeRate] = useState(10);
    const [recommendedFees, setRecommendedFees] = useState({});
    const [isConfirming, setIsConfirming] = useState(false);
    const [isTransactionInProgress, setIsTransactionInProgress] = useState(false);
    const [showCustomConfirmModal, setShowCustomConfirmModal] = useState(false);
    const [confirmationDetails, setConfirmationDetails] = useState({});
    const [hoveredBlocks, setHoveredBlocks] = useState({});
    const [searchBlock, setSearchBlock] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [transactionId, setTransactionId] = useState('');
    const [mintedBlocks, setMintedBlocks] = useState(new Set());
    const blockRefs = useRef({});
    const [isWalletConnected, setIsWalletConnected] = useState(false);

    useEffect(() => {
        const fetchRecommendedFees = async () => {
            try {
                const response = await axios.get('https://mempool.space/api/v1/fees/recommended');
                setRecommendedFees(response.data);
                setUserSelectedFeeRate(response.data.hourFee); // Set the default fee rate to the medium fee rate
            } catch (error) {
                console.error('Error fetching recommended fees:', error);
            }
        };

        fetchRecommendedFees();

        const fetchMintedBlocks = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/minted-blocks`);
                setMintedBlocks(new Set(response.data));
            } catch (error) {
                console.error('Error fetching minted blocks:', error);
            }
        };

        fetchMintedBlocks();

        socket.on('hover', (data) => {
            setHoveredBlocks((prev) => ({ ...prev, [data.blockIndex]: data.user }));
        });

        socket.on('leave', (data) => {
            setHoveredBlocks((prev) => {
                const updated = { ...prev };
                delete updated[data.blockIndex];
                return updated;
            });
        });

        socket.on('blockMinted', (data) => {
            setMintedBlocks((prev) => new Set(prev).add(data.blockIndex));
        });

        return () => {
            socket.off('hover');
            socket.off('leave');
            socket.off('blockMinted');
        };
    }, []);

    const handleBlockHover = (blockIndex) => {
        socket.emit('hover', { blockIndex, user: socket.id });
    };

    const handleBlockLeave = (blockIndex) => {
        socket.emit('leave', { blockIndex, user: socket.id });
    };

    const handleBlockClick = (blockIndex) => {
        if (!isTransactionInProgress && !mintedBlocks.has(blockIndex)) {
            if (!isWalletConnected) {
                alert('Please connect your UniSat wallet to proceed.');
                return;
            }
            setSelectedBlock(blockIndex);
            setIsConfirming(true);
        }
    };

    const handleConfirmPurchase = async () => {
        if (isTransactionInProgress) {
            console.log('Transaction already in progress - Ignoring additional request');
            return;
        }

        if (!selectedBlock || !walletAddress) {
            alert('Please connect your wallet and select a block.');
            return;
        }

        setIsTransactionInProgress(true);
        console.log('Transaction started');
        const feeRate = parseInt(userSelectedFeeRate, 10);

        try {
            const purchaseData = {
                blockIndex: selectedBlock,
                userSelectedFeeRate: feeRate,
                userSelectedReceiverAddress: walletAddress,
            };

            console.log('Sending purchase data to server', purchaseData);
            const response = await axios.post(`${API_BASE_URL}/api/inscribe`, purchaseData);
            console.log('Purchase data response', response.data);
            const result = response.data;

            setConfirmationDetails({
                payAddressAmount: result.payAddressAmount,
                payAddress: result.payAddress,
                receiverAddress: result.receiverAddress,
                feeRate: userSelectedFeeRate,
            });
            setShowCustomConfirmModal(true);
        } catch (error) {
            console.error("Error during the purchase request:", error);
            alert('An error occurred while processing your purchase.');
        } finally {
            setIsTransactionInProgress(false);
            setIsConfirming(false);
        }
    };

    const handlePasteAddress = async () => {
        try {
            const text = await navigator.clipboard.readText();
            setWalletAddress(text);
        } catch (error) {
            console.error('Failed to read clipboard contents:', error);
        }
    };

    const handleTransactionConfirmation = async () => {
        setShowCustomConfirmModal(false);

        if (window.unisat) {
            try {
                console.log('Sending payment via UniSat for the inscription fee');
                const txId = await window.unisat.sendBitcoin(
                    confirmationDetails.payAddress,
                    confirmationDetails.payAddressAmount,
                    { feeRate: confirmationDetails.feeRate }
                );
                console.log('Inscription fee transaction completed', txId);

                if (txId) {
                    console.log('Transaction ID:', txId);
                    setTransactionId(txId);
                    setMintedBlocks(prev => new Set(prev).add(selectedBlock)); // Mark the block as minted
                    socket.emit('mintBlock', { blockIndex: selectedBlock }); // Broadcast the minted block
                    setSelectedBlock(null);  // Reset selected block
                    setShowSuccessModal(true);  // Show success modal
                } else {
                    console.log('No transaction ID returned from payment');
                }
            } catch (error) {
                console.error("Error during the payment transaction:", error);
                alert('An error occurred while processing your payment.');
            }
        }
    };

    const handleSearchBlock = () => {
        const blockIndex = parseInt(searchBlock, 10);
        if (blockIndex >= 0 && blockIndex < 10000) {
            blockRefs.current[blockIndex].scrollIntoView({ behavior: 'smooth' });
        } else {
            alert('Invalid block number. Please enter a number between 0 and 9999.');
        }
    };

    const handleWalletConnected = (address) => {
        setWalletAddress(address);
        setIsWalletConnected(true);
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-gray-900 via-purple-900 to-black p-6">
            <div className="max-w-7xl mx-auto space-y-6">
                <div className="flex justify-between items-center bg-white/5 backdrop-blur-sm rounded-xl p-4">
                    <h1 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-400">
                        PMT MINT
                    </h1>
                    <WalletConnector onConnected={handleWalletConnected} />
                </div>
    
                <div className="bg-white/5 backdrop-blur-sm rounded-xl p-4 flex gap-4">
                    <input
                        type="number"
                        value={searchBlock}
                        onChange={(e) => setSearchBlock(e.target.value)}
                        placeholder="Enter block number"
                        className="flex-1 px-4 py-2 bg-white/10 border border-purple-500/30 rounded-lg
                                 text-white placeholder-gray-400 focus:outline-none focus:ring-2
                                 focus:ring-purple-500 focus:border-transparent"
                    />
                    <button 
                        onClick={handleSearchBlock}
                        className="px-6 py-2 bg-gradient-to-r from-purple-600 to-pink-600 rounded-lg
                                 font-bold text-white hover:shadow-lg hover:shadow-purple-500/25
                                 transition-all duration-200"
                    >
                        Search Block
                    </button>
                </div>
    
                <div className="bg-white/5 backdrop-blur-sm rounded-xl p-4">
                    <div className="grid grid-cols-[repeat(auto-fill,minmax(40px,1fr))] gap-1 max-h-[60vh] overflow-y-auto
                                  scrollbar-thin scrollbar-thumb-purple-500 scrollbar-track-purple-200/10">
                        {[...Array(10000).keys()].map(blockIndex => (
                            <div
                                key={blockIndex}
                                ref={(el) => (blockRefs.current[blockIndex] = el)}
                                className={`
                                    aspect-square rounded-lg cursor-pointer flex items-center justify-center
                                    text-xs font-medium text-white relative transition-all duration-200
                                    ${mintedBlocks.has(blockIndex) 
                                        ? 'bg-gradient-to-br from-purple-800/50 to-pink-800/50 cursor-not-allowed' 
                                        : 'bg-gradient-to-br from-purple-500 to-pink-500 hover:scale-105 hover:shadow-lg hover:shadow-purple-500/25'}
                                    ${selectedBlock === blockIndex ? 'ring-2 ring-white' : ''}
                                    ${hoveredBlocks[blockIndex] ? 'scale-105' : ''}
                                `}
                                onClick={() => handleBlockClick(blockIndex)}
                                onMouseEnter={() => handleBlockHover(blockIndex)}
                                onMouseLeave={() => handleBlockLeave(blockIndex)}
                            >
                                {blockIndex}
                                {hoveredBlocks[blockIndex] && (
                                    <span className="absolute text-lg animate-bounce">👾</span>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
    
                {isConfirming && (
                    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
                        <div className="bg-white rounded-2xl p-6 max-w-md w-full space-y-4 animate-fadeIn">
                            <h2 className="text-2xl font-bold text-gray-800">Confirm Purchase</h2>
                            <p className="text-gray-600">You are about to inscribe block: {selectedBlock}</p>
                            
                            <div className="space-y-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Receiver Address
                                    </label>
                                    <div className="flex gap-2">
                                        <input
                                            type="text"
                                            value={walletAddress}
                                            onChange={(e) => setWalletAddress(e.target.value)}
                                            className="flex-1 px-4 py-2 border border-purple-500/30 rounded-lg
                                                     focus:outline-none focus:ring-2 focus:ring-purple-500"
                                            placeholder="Enter receiver address"
                                        />
                                        <button 
                                            onClick={handlePasteAddress}
                                            className="px-4 py-2 bg-purple-100 text-purple-600 rounded-lg 
                                                     hover:bg-purple-200 transition-colors duration-200"
                                        >
                                            Paste
                                        </button>
                                    </div>
                                </div>
    
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Fee Rate (sat/vB)
                                    </label>
                                    <input
                                        type="number"
                                        value={userSelectedFeeRate}
                                        onChange={(e) => setUserSelectedFeeRate(e.target.value)}
                                        min="1"
                                        className="w-full px-4 py-2 border border-purple-500/30 rounded-lg
                                                 focus:outline-none focus:ring-2 focus:ring-purple-500"
                                    />
                                </div>
    
                                {recommendedFees.fastestFee && (
                                    <div className="grid grid-cols-3 gap-2">
                                        <div className="p-2 bg-purple-50 rounded-lg text-center hover:bg-purple-100 transition-colors duration-200">
                                            <p className="text-xs text-gray-600">Fastest</p>
                                            <p className="font-bold text-purple-600">{recommendedFees.fastestFee}</p>
                                            <p className="text-xs text-gray-500">sat/vB</p>
                                        </div>
                                        <div className="p-2 bg-purple-50 rounded-lg text-center hover:bg-purple-100 transition-colors duration-200">
                                            <p className="text-xs text-gray-600">Half Hour</p>
                                            <p className="font-bold text-purple-600">{recommendedFees.halfHourFee}</p>
                                            <p className="text-xs text-gray-500">sat/vB</p>
                                        </div>
                                        <div className="p-2 bg-purple-50 rounded-lg text-center hover:bg-purple-100 transition-colors duration-200">
                                            <p className="text-xs text-gray-600">Hour</p>
                                            <p className="font-bold text-purple-600">{recommendedFees.hourFee}</p>
                                            <p className="text-xs text-gray-500">sat/vB</p>
                                        </div>
                                    </div>
                                )}
                            </div>
    
                            <div className="flex gap-2 pt-4">
                                <button
                                    onClick={handleConfirmPurchase}
                                    disabled={isTransactionInProgress}
                                    className="flex-1 py-3 bg-gradient-to-r from-purple-600 to-pink-600 rounded-lg
                                             text-white font-bold disabled:opacity-50 hover:shadow-lg 
                                             hover:shadow-purple-500/25 transition-all duration-200"
                                >
                                    {isTransactionInProgress ? 'Processing...' : 'Confirm Purchase'}
                                </button>
                                <button
                                    onClick={() => setIsConfirming(false)}
                                    className="flex-1 py-3 border border-gray-300 text-gray-700 rounded-lg 
                                             font-bold hover:border-gray-400 transition-all duration-200"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                )}
    
                <ConfirmPurchaseModal
                    isOpen={showCustomConfirmModal}
                    onConfirm={handleTransactionConfirmation}
                    onCancel={() => setShowCustomConfirmModal(false)}
                    paymentDetails={confirmationDetails}
                />
                
                <PurchaseSuccessModal
                    isOpen={showSuccessModal}
                    transactionId={transactionId}
                    onClose={() => setShowSuccessModal(false)}
                />
            </div>
        </div>
    );
};

export default PMT;
