import React, { useState, useEffect } from 'react'; 
import axios from 'axios';
import { motion } from 'framer-motion';
import { QRCodeSVG } from 'qrcode.react';

const ConfirmPurchaseModal = ({ isOpen, onConfirm, onCancel, paymentDetails }) => {
    const [btcPriceInUsd, setBtcPriceInUsd] = useState(null);
    const [copiedField, setCopiedField] = useState(null);

    useEffect(() => {
        if (isOpen) {
            axios.get('https://mempool.space/api/v1/prices')
                .then((response) => {
                    setBtcPriceInUsd(response.data.USD);
                })
                .catch((error) => {
                    console.error('Error fetching BTC price:', error);
                });
        }
    }, [isOpen]);

    const convertSatoshiToUsd = (satoshi) => {
        return ((satoshi / 100000000) * btcPriceInUsd).toFixed(2);
    };

    const copyToClipboard = (text, field) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                setCopiedField(field);
                setTimeout(() => setCopiedField(null), 2000);
            })
            .catch((err) => {
                console.error('Failed to copy text: ', err);
            });
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black/90 backdrop-blur-md flex items-start justify-center z-50 p-4 overflow-y-auto">
            <motion.div
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                className="relative bg-gradient-to-b from-gray-900 to-purple-900/90 rounded-3xl max-w-2xl w-full 
                         overflow-hidden shadow-2xl border border-purple-500/20 my-8"
            >
                {/* Modal Header */}
                <div className="sticky top-0 z-10 px-8 py-5 bg-black/40 border-b border-purple-500/20">
                    <h2 className="text-2xl font-bold text-white">Confirm Your Purchase</h2>
                </div>

                {/* Modal Content */}
                <div className="p-8 space-y-6">
                    {/* Payment Amount */}
                    <div className="bg-black/20 rounded-2xl p-6 border border-purple-500/10 space-y-4">
                        <div className="flex justify-between items-center">
                            <span className="text-purple-200">Total Cost</span>
                            <div className="text-right">
                                <div className="text-xl font-bold text-white">
                                    {(paymentDetails.payAddressAmount / 100000000).toFixed(8)} BTC
                                </div>
                                <div className="text-sm text-purple-200">
                                    ${convertSatoshiToUsd(paymentDetails.payAddressAmount)} USD
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Payment Address */}
                    <div className="space-y-3">
                        <label className="block text-sm font-medium text-purple-200">Pay Address</label>
                        <div className="relative">
                            <div className="bg-black/20 rounded-xl p-4 pr-24 border border-purple-500/10 
                                          font-mono text-purple-100 text-sm break-all">
                                {paymentDetails.payAddress}
                            </div>
                            <button
                                onClick={() => copyToClipboard(paymentDetails.payAddress, 'address')}
                                className="absolute right-2 top-1/2 -translate-y-1/2 px-4 py-2
                                         bg-purple-500/20 hover:bg-purple-500/30 rounded-lg
                                         text-purple-200 text-sm font-medium transition-colors"
                            >
                                {copiedField === 'address' ? 'Copied!' : 'Copy'}
                            </button>
                        </div>
                    </div>

                    {/* Amount to Send */}
                    <div className="space-y-3">
                        <label className="block text-sm font-medium text-purple-200">Amount to Send</label>
                        <div className="relative">
                            <div className="bg-black/20 rounded-xl p-4 pr-24 border border-purple-500/10 
                                          font-mono text-purple-100 text-sm">
                                {(paymentDetails.payAddressAmount / 100000000).toFixed(8)} BTC
                            </div>
                            <button
                                onClick={() => copyToClipboard((paymentDetails.payAddressAmount / 100000000).toFixed(8), 'amount')}
                                className="absolute right-2 top-1/2 -translate-y-1/2 px-4 py-2
                                         bg-purple-500/20 hover:bg-purple-500/30 rounded-lg
                                         text-purple-200 text-sm font-medium transition-colors"
                            >
                                {copiedField === 'amount' ? 'Copied!' : 'Copy'}
                            </button>
                        </div>
                    </div>

                    {/* QR Code */}
                    <div className="flex justify-center p-6 bg-black/20 rounded-2xl border border-purple-500/10">
                        <div className="bg-white p-4 rounded-xl">
                            <QRCodeSVG 
                                value={`bitcoin:${paymentDetails.payAddress}?amount=${(paymentDetails.payAddressAmount / 100000000).toFixed(8)}`}
                                size={200}
                                level="H"
                            />
                        </div>
                    </div>

                    {/* Additional Details */}
                    <div className="grid grid-cols-2 gap-4 bg-black/20 rounded-xl p-4 border border-purple-500/10">
                        <div>
                            <span className="text-sm text-purple-200">Receiver Address</span>
                            <p className="font-medium text-white truncate" title={paymentDetails.receiverAddress}>
                                {paymentDetails.receiverAddress}
                            </p>
                        </div>
                        <div>
                            <span className="text-sm text-purple-200">Fee Rate</span>
                            <p className="font-medium text-white">{paymentDetails.feeRate} sat/vB</p>
                        </div>
                    </div>

                    {/* Instructions */}
                    <div className="space-y-2 text-sm text-purple-200/80">
                        <p>1. Send the exact amount to the address above</p>
                        <p>2. Wait for network confirmation</p>
                        <p>3. Your NFT will be delivered to your wallet</p>
                    </div>
                </div>

                {/* Modal Footer */}
                <div className="sticky bottom-0 px-8 py-6 bg-black/40 border-t border-purple-500/20">
                    <div className="flex gap-4">
                        <motion.button
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.98 }}
                            onClick={onConfirm}
                            className="flex-1 py-4 bg-gradient-to-r from-purple-600 to-pink-600 rounded-xl
                                     text-lg font-semibold text-white hover:shadow-lg hover:shadow-purple-500/25
                                     transition-all"
                        >
                            Confirm with UniSat
                        </motion.button>
                        <button
                            onClick={onCancel}
                            className="px-8 py-4 bg-gray-800/50 hover:bg-gray-800 text-white rounded-xl
                                     text-lg font-semibold transition-colors"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </motion.div>
        </div>
    );
};

export default ConfirmPurchaseModal;