import React, { useState, useEffect } from 'react';
import { getBalance } from '../api/walletApi';
import axios from 'axios';
import { motion } from 'framer-motion';

const BalanceDisplay = () => {
    const [balance, setBalance] = useState(null);
    const [btcPrice, setBtcPrice] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchBalanceAndBtcPrice = async () => {
            try {
                const fetchedBalance = await getBalance();
                setBalance(fetchedBalance);
                
                const pricesResponse = await axios.get('https://mempool.space/api/v1/prices');
                setBtcPrice(pricesResponse.data.USD);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchBalanceAndBtcPrice();
    }, []);

    const convertSatoshisToUsd = (satoshis) => {
        if (!btcPrice) return 0;
        const satoshisInOneBtc = 100000000;
        return ((satoshis / satoshisInOneBtc) * btcPrice).toFixed(2);
    };

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="p-6 bg-white rounded-2xl shadow-xl border border-purple-500/20"
        >
            <h2 className="text-2xl font-bold text-black mb-6">Your Balance</h2>
            
            {isLoading ? (
                <div className="flex items-center justify-center py-8">
                    <div className="w-8 h-8 border-4 border-purple-500 border-t-transparent rounded-full animate-spin"></div>
                </div>
            ) : balance && btcPrice ? (
                <div className="space-y-4">
                    {/* Confirmed Balance */}
                    <motion.div 
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        className="p-4 bg-gradient-to-r from-purple-50 to-pink-50 rounded-xl border border-purple-500/10"
                    >
                        <div className="flex justify-between items-center">
                            <span className="text-gray-600 font-medium">Confirmed</span>
                            <div className="text-right">
                                <p className="text-black font-bold">{balance.confirmed.toLocaleString()} sats</p>
                                <p className="text-sm text-gray-500">${convertSatoshisToUsd(balance.confirmed)} USD</p>
                            </div>
                        </div>
                    </motion.div>

                    {/* Unconfirmed Balance */}
                    <motion.div 
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: 0.1 }}
                        className="p-4 bg-gradient-to-r from-purple-50 to-pink-50 rounded-xl border border-purple-500/10"
                    >
                        <div className="flex justify-between items-center">
                            <span className="text-gray-600 font-medium">Unconfirmed</span>
                            <div className="text-right">
                                <p className="text-black font-bold">{balance.unconfirmed.toLocaleString()} sats</p>
                                <p className="text-sm text-gray-500">${convertSatoshisToUsd(balance.unconfirmed)} USD</p>
                            </div>
                        </div>
                    </motion.div>

                    {/* Total Balance */}
                    <motion.div 
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: 0.2 }}
                        className="p-4 bg-gradient-to-r from-purple-600 to-pink-600 rounded-xl border border-purple-500/10"
                    >
                        <div className="flex justify-between items-center">
                            <span className="text-white font-medium">Total Balance</span>
                            <div className="text-right">
                                <p className="text-white font-bold">{balance.total.toLocaleString()} sats</p>
                                <p className="text-white/80">${convertSatoshisToUsd(balance.total)} USD</p>
                            </div>
                        </div>
                    </motion.div>

                    {/* Current BTC Price */}
                    <div className="mt-6 text-center text-sm text-gray-500">
                        Current BTC Price: ${btcPrice.toLocaleString()} USD
                    </div>
                </div>
            ) : (
                <div className="text-center py-8 text-gray-500">
                    Failed to load balance. Please try again later.
                </div>
            )}
        </motion.div>
    );
};

export default BalanceDisplay;