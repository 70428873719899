import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import WalletConnector from './WalletConnector';
import PurchaseSuccessModal from './PurchaseSuccessModal';
import ConfirmPurchaseModal from './ConfirmPurchaseModal';

const API_BASE_URL = 'https://gallery-service-production.up.railway.app';

const Gallery = () => {
    const [artworks, setArtworks] = useState([]);
    const [selectedArtwork, setSelectedArtwork] = useState(null);
    const [walletAddress, setWalletAddress] = useState('');
    const [userSelectedFeeRate, setUserSelectedFeeRate] = useState(10);
    const [recommendedFees, setRecommendedFees] = useState({});
    const [isConfirming, setIsConfirming] = useState(false);
    const [isTransactionInProgress, setIsTransactionInProgress] = useState(false);
    const [selectedArtist, setSelectedArtist] = useState('all');
    const [artists, setArtists] = useState([]);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [transactionId, setTransactionId] = useState('');
    const [showCustomConfirmModal, setShowCustomConfirmModal] = useState(false);
    const [confirmationDetails, setConfirmationDetails] = useState({});

    useEffect(() => {
        const fetchArtworksAndArtists = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/artworks`);
                setArtworks(response.data);
                const uniqueArtists = [...new Set(response.data.map(item => item.artist))];
                setArtists(uniqueArtists);
            } catch (error) {
                console.error("Error fetching artworks:", error);
            }

            try {
                const feesResponse = await axios.get('https://mempool.space/api/v1/fees/recommended');
                setRecommendedFees(feesResponse.data);
            } catch (error) {
                console.error('Error fetching recommended fees:', error);
            }
        };

        fetchArtworksAndArtists();
    }, []);

    const handleBuy = artwork => {
        if (!isTransactionInProgress) {
            setSelectedArtwork(artwork);
            setIsConfirming(true);
        }
    };

    const handleConfirmPurchase = async () => {
        if (isTransactionInProgress) {
            console.log('Transaction already in progress - Ignoring additional request');
            return;
        }
    
        if (!selectedArtwork || !walletAddress) {
            alert('Please connect your wallet and select an artwork.');
            return;
        }
    
        setIsTransactionInProgress(true);
        console.log('Transaction started');
        const feeRate = parseInt(userSelectedFeeRate, 10);
    
        try {
            const purchaseData = {
                artworkId: selectedArtwork.id,
                userSelectedFeeRate: feeRate,
                userSelectedReceiverAddress: walletAddress,
            };
    
            console.log('Sending purchase data to server', purchaseData);
            const response = await axios.post(`${API_BASE_URL}/api/purchase`, purchaseData);
            console.log('Purchase data response', response.data);
            const result = response.data;
    
            setConfirmationDetails({
                payAddressAmount: result.payAddressAmount,
                payAddress: result.payAddress,
                receiverAddress: result.receiverAddress,
                feeRate: userSelectedFeeRate,
            });
            setShowCustomConfirmModal(true);
        } catch (error) {
            console.error("Error during the purchase request:", error);
            alert('An error occurred while processing your purchase.');
        } finally {
            setIsTransactionInProgress(false);
            setIsConfirming(false);
        }
    };

    const handlePasteAddress = async () => {
        try {
            const text = await navigator.clipboard.readText();
            setWalletAddress(text);
        } catch (error) {
            console.error('Failed to read clipboard contents:', error);
        }
    };

    const handleTransactionConfirmation = async () => {
        setShowCustomConfirmModal(false);
    
        if (window.unisat) {
            try {
                console.log('Sending payment via UniSat for the inscription fee');
                const txId = await window.unisat.sendBitcoin(
                    confirmationDetails.payAddress, 
                    confirmationDetails.payAddressAmount, 
                    { feeRate: confirmationDetails.feeRate }
                );
                console.log('Inscription fee transaction completed', txId);
    
                if (txId) {
                    console.log('Transaction ID:', txId);
                    setTransactionId(txId);

                
                    const newSoldCount = selectedArtwork.sold + 1;

                   
                    await axios.post(`${API_BASE_URL}/api/artworks/update-supply`, {
                        artworkId: selectedArtwork.id,
                        sold: newSoldCount
                    });

                    
                    setArtworks(artworks.map(art => {
                        if (art.id === selectedArtwork.id) {
                            return { ...art, sold: newSoldCount };
                        }
                        return art;
                    }));

                    setShowSuccessModal(true);
                } else {
                    console.log('No transaction ID returned from payment');
                }
            } catch (error) {
                console.error("Error during the payment transaction:", error);
                alert('An error occurred while processing your payment.');
            }
        }
    };
   
    return (
        <div className="min-h-screen bg-gradient-to-b from-gray-900 via-purple-900 to-black text-white">
            {/* Header Section */}
            <div className="sticky top-0 z-30 bg-black/30 backdrop-blur-sm">
                <div className="container mx-auto px-6 py-4">
                    <div className="flex justify-between items-center">
                        <div className="flex items-center space-x-4">
                            <h1 className="text-2xl font-bold text-white">Gallery</h1>
                            <div className="relative">
                                <select 
                                    value={selectedArtist} 
                                    onChange={(e) => setSelectedArtist(e.target.value)}
                                    className="appearance-none px-4 py-2 pr-8 rounded-full bg-purple-900/20 border border-purple-500/30 
                                             text-purple-100 focus:outline-none hover:bg-purple-900/40 transition-all cursor-pointer"
                                >
                                    <option value="all">All Artists</option>
                                    {artists.map((artist, index) => (
                                        <option key={index} value={artist}>{artist}</option>
                                    ))}
                                </select>
                                <div className="absolute right-3 top-1/2 -translate-y-1/2 pointer-events-none">
                                    <svg className="w-4 h-4 text-purple-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <WalletConnector onConnected={setWalletAddress} />
                    </div>
                </div>
            </div>
    
            {/* Gallery Grid */}
            <div className="container mx-auto px-6 py-12">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 auto-rows-max">
                    {artworks
                        .filter(artwork => selectedArtist === 'all' || artwork.artist === selectedArtist)
                        .map((artwork, index) => (
                            <motion.div
                                key={artwork.id}
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: index * 0.1 }}
                                className={`group relative ${index % 3 === 1 ? 'md:mt-12' : ''}`}
                            >
                                {/* Artwork Card */}
                                <div className="relative rounded-2xl overflow-hidden bg-black/40 backdrop-blur-sm 
                                              border border-purple-500/20 transition-all duration-500 ease-out
                                              hover:border-purple-500/40 hover:shadow-xl hover:shadow-purple-500/10
                                              hover:translate-y-[-4px]">
                                    {/* Artwork Display */}
                                    <div className="aspect-square w-full relative">
                                        <iframe 
                                            src={artwork.iframeSrc} 
                                            title={artwork.title} 
                                            frameBorder="0" 
                                            scrolling="no" 
                                            className="absolute inset-0 w-full h-full"
                                            allowFullScreen
                                        />
                                        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-black/20 to-black/90 
                                                      opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
                                    </div>
    
                                    {/* Artwork Info */}
                                    <div className="absolute bottom-0 left-0 right-0 p-6 translate-y-full 
                                                  group-hover:translate-y-0 transition-transform duration-500 ease-out">
                                        <div className="space-y-4">
                                            <div className="flex justify-between items-start">
                                                <h3 className="text-xl font-bold text-white max-w-[80%]">{artwork.title}</h3>
                                                <span className="px-3 py-1 bg-purple-500/30 backdrop-blur-sm rounded-full 
                                                             text-sm font-medium text-purple-100">
                                                    {artwork.items - artwork.sold} / {artwork.items}
                                                </span>
                                            </div>
    
                                            <p className="text-base text-purple-100/90 line-clamp-2">
                                                {artwork.description}
                                            </p>
    
                                            <div className="flex justify-between items-center pt-2">
                                                <div className="flex flex-col">
                                                    <span className="text-sm text-purple-200">Price</span>
                                                    <span className="text-xl font-bold text-white">
                                                        {artwork.price} BTC
                                                    </span>
                                                </div>
                                                <motion.button
                                                    whileHover={{ scale: 1.05 }}
                                                    whileTap={{ scale: 0.95 }}
                                                    onClick={() => handleBuy(artwork)}
                                                    disabled={isTransactionInProgress || artwork.items - artwork.sold <= 0}
                                                    className={`px-6 py-2.5 rounded-xl font-semibold transition-all
                                                        ${artwork.items - artwork.sold > 0 
                                                            ? 'bg-gradient-to-r from-purple-600 to-pink-600 text-white hover:shadow-lg hover:shadow-purple-500/25' 
                                                            : 'bg-gray-800/50 text-gray-400 cursor-not-allowed'}`}
                                                >
                                                    {artwork.items - artwork.sold > 0 ? 'Buy Now' : 'Sold Out'}
                                                </motion.button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        ))}
                </div>
            </div>
                    {/* Purchase Modal */}
        {isConfirming && selectedArtwork && (
            <div className="fixed inset-0 bg-black/90 backdrop-blur-md flex items-start justify-center z-50 p-4 overflow-y-auto">
                <motion.div 
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="bg-gradient-to-b from-gray-900 to-purple-900/90 rounded-3xl max-w-4xl w-full 
                             overflow-hidden shadow-2xl border border-purple-500/20 my-8"
                >
                    {/* Modal Header */}
                    <div className="px-8 py-5 bg-black/40 border-b border-purple-500/20 flex justify-between items-center">
                        <h2 className="text-2xl font-bold text-white">Complete Purchase</h2>
                        <button 
                            onClick={() => setIsConfirming(false)}
                            className="p-2 hover:bg-purple-500/20 rounded-full transition-colors"
                        >
                            <svg className="w-6 h-6 text-purple-200" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>

                    <div className="p-8 space-y-8">
                        {/* Artwork Preview and Details */}
                        <div className="grid md:grid-cols-2 gap-8">
                            {/* Artwork Preview */}
                            <div className="relative aspect-square rounded-2xl overflow-hidden border border-purple-500/20 
                                          shadow-lg shadow-purple-500/10 bg-black/20">
                                <iframe
                                    src={selectedArtwork.iframeSrc}
                                    className="absolute inset-0 w-full h-full"
                                    frameBorder="0"
                                    allowFullScreen
                                />
                            </div>

                            {/* Artwork Details */}
                            <div className="space-y-6">
                                <div>
                                    <h3 className="text-2xl font-bold text-white mb-3">{selectedArtwork.title}</h3>
                                    <p className="text-lg text-purple-100/90">{selectedArtwork.description}</p>
                                </div>
                                
                                <div className="grid grid-cols-2 gap-4 bg-purple-900/20 p-5 rounded-2xl border border-purple-500/10">
                                    <div>
                                        <span className="text-sm text-purple-200">Available</span>
                                        <p className="text-xl font-bold text-white">
                                            {selectedArtwork.items - selectedArtwork.sold} / {selectedArtwork.items}
                                        </p>
                                    </div>
                                    <div>
                                        <span className="text-sm text-purple-200">Price</span>
                                        <p className="text-xl font-bold text-white">{selectedArtwork.price} BTC</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Purchase Form */}
                        <div className="space-y-6 bg-black/20 p-6 rounded-2xl border border-purple-500/10">
                            {/* Receiver Address */}
                            <div className="space-y-3">
                                <label className="block text-lg font-medium text-purple-100">Receiver Address</label>
                                <div className="relative">
                                    <input
                                        type="text"
                                        value={walletAddress}
                                        onChange={(e) => setWalletAddress(e.target.value)}
                                        className="w-full px-4 py-3 bg-purple-900/20 border border-purple-500/30 rounded-xl
                                                 text-white text-lg focus:outline-none focus:ring-2 focus:ring-purple-500 pr-24"
                                        placeholder="Enter BTC address"
                                    />
                                    <button
                                        onClick={handlePasteAddress}
                                        className="absolute right-2 top-1/2 -translate-y-1/2 px-4 py-2
                                                 bg-purple-500/20 hover:bg-purple-500/30 rounded-lg text-purple-100
                                                 transition-colors text-base font-medium"
                                    >
                                        Paste
                                    </button>
                                </div>
                            </div>

                            {/* Network Fee Selection */}
                            <div className="space-y-3">
                                <label className="block text-lg font-medium text-purple-100">Network Fee Rate</label>
                                <div className="space-y-4">
                                    <div className="grid grid-cols-3 gap-4">
                                        {[
                                            { label: 'Fast', value: recommendedFees.fastestFee, icon: '⚡️' },
                                            { label: 'Medium', value: recommendedFees.halfHourFee, icon: '🕒' },
                                            { label: 'Slow', value: recommendedFees.hourFee, icon: '🕐' }
                                        ].map((fee, index) => (
                                            <button 
                                                key={index}
                                                onClick={() => setUserSelectedFeeRate(fee.value)}
                                                className={`bg-purple-900/30 rounded-xl p-4 text-center transition-all duration-300
                                                          border border-purple-500/10 hover:border-purple-500/30
                                                          ${userSelectedFeeRate === fee.value ? 'ring-2 ring-purple-500' : ''}`}
                                            >
                                                <p className="text-2xl mb-1">{fee.icon}</p>
                                                <p className="text-sm text-purple-200">{fee.label}</p>
                                                <p className="text-lg font-bold text-white">{fee.value} sat/vB</p>
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Modal Footer */}
                    <div className="px-8 py-6 bg-black/40 border-t border-purple-500/20">
                        <div className="flex gap-4">
                            <motion.button
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 0.98 }}
                                onClick={handleConfirmPurchase}
                                disabled={isTransactionInProgress}
                                className="flex-1 py-4 bg-gradient-to-r from-purple-600 to-pink-600 rounded-xl
                                         text-lg font-semibold text-white hover:shadow-lg hover:shadow-purple-500/25
                                         transition-all disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                Continue to Payment
                            </motion.button>
                            <button
                                onClick={() => setIsConfirming(false)}
                                className="px-8 py-4 bg-gray-800/50 hover:bg-gray-800 text-white rounded-xl
                                         text-lg font-semibold transition-colors"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </motion.div>
            </div>
        )}

        {/* Payment Confirmation Modal */}
        <ConfirmPurchaseModal
            isOpen={showCustomConfirmModal}
            onConfirm={handleTransactionConfirmation}
            onCancel={() => setShowCustomConfirmModal(false)}
            paymentDetails={confirmationDetails}
        />

        {/* Success Modal */}
        <PurchaseSuccessModal
            isOpen={showSuccessModal}
            transactionId={transactionId}
            artworkSrc={selectedArtwork ? selectedArtwork.iframeSrc : ''}
            onClose={() => setShowSuccessModal(false)}
        />
    </div>
);
};

export default Gallery;
