import React, { useState, useEffect } from "react";
import { gzipSync } from "fflate";
import axios from 'axios';
import FFT from 'fft.js';
import "./SoundMagic.css";
import ConfirmPurchaseModal from './ConfirmPurchaseModal';

const BACKEND_URL = 'https://inscription-service-production.up.railway.app';
const FILE_SIZE_WARNING_THRESHOLD = 350;
const MAX_DURATION_SECONDS = 30;
const DEFAULT_SAMPLE_RATE = 44100;

const SoundMagic = () => {
  const [encodedData, setEncodedData] = useState("");
  const [generatedHTML, setGeneratedHTML] = useState("");
  const [previewHTML, setPreviewHTML] = useState("");
  const [includeBackground, setIncludeBackground] = useState(false);
  const [inscriptionId, setInscriptionId] = useState("");
  const [downsampleFactor, setDownsampleFactor] = useState(4);
  const [quantizationBits, setQuantizationBits] = useState(4);
  const [scaleFactor, setScaleFactor] = useState(7.5);
  const [duration, setDuration] = useState(10);
  const [fileSize, setFileSize] = useState({
    original: 0,
    compressed: 0,
    compressionRatio: 0
  });
  const [orderDetails, setOrderDetails] = useState({
    totalAmount: 0,
    totalAmountBTC: '',
    payAddress: '',
    receiverAddress: '',
    devAddress: '',
    devFee: 0,
    feeRate: null,
  });
  const [recommendedFees, setRecommendedFees] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showSizeWarning, setShowSizeWarning] = useState(false);
  const MAX_DURATION_SECONDS = 30;
  const pakoUrl = "/content/fba6f95fb1152db43304a27dce8cb8c65509eba6ab0b6958cedeb33e5f443077i0";
  const [noiseGateThreshold, setNoiseGateThreshold] = useState(-60);
  const [compressorThreshold, setCompressorThreshold] = useState(-24);
  const [compressorRatio, setCompressorRatio] = useState(4);
  const [highCutFreq, setHighCutFreq] = useState(16000);
  const [lowCutFreq, setLowCutFreq] = useState(20);
  const [noiseReduction, setNoiseReduction] = useState(0.5);

  const [audioContext] = useState(() => new (window.AudioContext || window.webkitAudioContext)());
  const [sampleRate, setSampleRate] = useState(DEFAULT_SAMPLE_RATE);
  const [errorMessage, setErrorMessage] = useState(null);
  const [rawFileSize, setRawFileSize] = useState(0);

  useEffect(() => {
    const fetchRecommendedFees = async () => {
      try {
        const response = await axios.get('https://mempool.space/api/v1/fees/recommended');
        setRecommendedFees(response.data);
        setOrderDetails(prevDetails => ({
          ...prevDetails,
          feeRate: response.data.fastestFee
        }));
      } catch (error) {
        console.error('Error fetching recommended fees:', error);
        setOrderDetails(prevDetails => ({
          ...prevDetails,
          feeRate: 10
        }));
      }
    };
    fetchRecommendedFees();
  }, []);

  const handleAudioUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const size = file.size;
    setRawFileSize(size);
    
    try {
      const arrayBuffer = await file.arrayBuffer();
      if (arrayBuffer.byteLength > 10000000) {
        setErrorMessage("File too large. Please choose a smaller time frame or lower bit rate.");
        return;
      }

      audioContext.decodeAudioData(arrayBuffer, (audioBuffer) => {
        try {
          setSampleRate(audioBuffer.sampleRate);
          const truncatedBuffer = truncateAudioBuffer(audioBuffer, duration, audioContext);
          const encoded = optimizeAndEncodePCMData(truncatedBuffer);
          setEncodedData(encoded);
          generateHTML(encoded, size);
          setErrorMessage(null);
        } catch (error) {
          console.error('Processing error:', error);
          setErrorMessage("File too complex. Please reduce duration or bit rate.");
        }
      });
    } catch (error) {
      console.error('Upload error:', error);
      setErrorMessage("Error processing audio. Please try different settings.");
    }
  };

  const truncateAudioBuffer = (audioBuffer, maxDuration, audioContext) => {
    const sampleRate = audioBuffer.sampleRate;
    const maxSamples = maxDuration * sampleRate;
    const truncatedData = audioBuffer.getChannelData(0).slice(0, maxSamples);
    const truncatedAudioBuffer = audioContext.createBuffer(1, truncatedData.length, sampleRate);
    truncatedAudioBuffer.copyToChannel(truncatedData, 0);
    return truncatedAudioBuffer;
  };

  const optimizeAndEncodePCMData = (audioBuffer) => {
    const downsampledData = downsampleWithFilter(audioBuffer.getChannelData(0), downsampleFactor);
    const normalizedData = normalizeAudio(downsampledData);
    const quantizedData = quantize(normalizedData, quantizationBits);
    const binaryData = Uint8Array.from(quantizedData);
    const compressedData = gzipSync(binaryData);
    return btoa(String.fromCharCode(...compressedData));
  };

  const downsampleWithFilter = (data, factor) => {
    const filteredData = [];
    for (let i = 0; i < data.length; i += factor) {
      let sum = 0;
      for (let j = 0; j < factor && i + j < data.length; j++) {
        sum += data[i + j];
      }
      filteredData.push(sum / factor);
    }
    return filteredData;
  };

  const normalizeAudio = (data) => {
    let maxAmp = 0;
    for (let i = 0; i < data.length; i++) {
      maxAmp = Math.max(maxAmp, Math.abs(data[i]));
    }
    const normalized = new Array(data.length);
    for (let i = 0; i < data.length; i++) {
      normalized[i] = data[i] / (maxAmp || 1);
    }
    return normalized;
  };

  const quantize = (data, bitDepth) => {
    const maxVal = Math.pow(2, bitDepth) - 1;
    return data.map((value) => Math.round((value + 1) * (maxVal / 2)));
  };

  const calculateFileSize = (htmlContent, originalSize) => {
    const encoder = new TextEncoder();
    const finalSize = encoder.encode(htmlContent).length;
    
    // Convert to KB
    const finalSizeKB = finalSize / 1024;
    const originalSizeKB = originalSize / 1024;
    
    // Calculate compression ratio
    let ratio = 0;
    if (originalSizeKB > 0) {
        ratio = ((originalSizeKB - finalSizeKB) / originalSizeKB * 100).toFixed(1);
    }

    console.log('Original size (KB):', originalSizeKB);
    console.log('Final size (KB):', finalSizeKB);
    console.log('Compression ratio:', ratio);

    return {
        original: originalSizeKB.toFixed(2),
        compressed: finalSizeKB.toFixed(2),
        compressionRatio: ratio
    };
  };

  const generatePreviewHTML = (htmlContent) => {
    if (!htmlContent) return '';
    return htmlContent
      .replace(/['"]\/content\/(.*?)['"](?![^<]*<\/script>)/g, '"https://ordin-delta.vercel.app/content/$1"')
      .replace(
        `"${pakoUrl}"`, 
        `"https://ordin-delta.vercel.app/content/fba6f95fb1152db43304a27dce8cb8c65509eba6ab0b6958cedeb33e5f443077i0"`
      );
  };

  const generateHTML = (encoded = encodedData, originalSize = 0) => {
    if (!encoded) return;

    const baseSampleRate = 44100;
    const playbackSampleRate = Math.max(3000, Math.min(baseSampleRate / downsampleFactor, 768000));

    const htmlContent = `<!DOCTYPE html>
<html>
<head>
  <style>
    body { margin: 0; overflow: hidden; background: #000; }
    iframe { width: 100%; height: 100%; border: none; position: absolute; top: 0; left: 0; }
    .play-button { 
      position: absolute; 
      top: 20px; 
      left: 20px; 
      z-index: 10; 
      padding: 8px 16px; 
      background: rgba(0, 0, 0, 0.6); 
      color: #fff; 
      border: 2px solid rgba(255, 255, 255, 0.4);
      border-radius: 20px;
      cursor: pointer;
      font-family: Arial, sans-serif;
      font-size: 14px;
      transition: all 0.3s ease;
      backdrop-filter: blur(5px);
    }
    .play-button:hover {
      background: rgba(0, 0, 0, 0.8);
      border-color: rgba(255, 255, 255, 0.8);
    }
  </style>
  <script>
    let pakoModule;
    const loadPako = async () => {
      if (!pakoModule) {
        pakoModule = await import("${pakoUrl}");
      }
      return pakoModule;
    };
  </script>
</head>
<body>
  ${includeBackground && inscriptionId ? `<iframe id="generativeArt" src="/content/${inscriptionId}" style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; border: none; pointer-events: none;"></iframe>` : ''}
  <button class="play-button" id="playButton">▶ Play</button>
  <script>
    const encodedData = "${encoded}";
    const decodePCMData = async () => {
      const pako = await loadPako();
      const compressed = atob(encodedData).split('').map(c => c.charCodeAt(0));
      const decompressed = pako.inflate(new Uint8Array(compressed));
      const channelData = Float32Array.from(decompressed, v => v / ${scaleFactor} - 1);
      return channelData;
    };
    const playAudioLoop = async () => {
      const channelData = await decodePCMData();
      const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
      const audioBuffer = audioCtx.createBuffer(1, channelData.length, ${playbackSampleRate});
      audioBuffer.copyToChannel(channelData, 0);
      const source = audioCtx.createBufferSource();
      source.buffer = audioBuffer;
      source.loop = true;
      source.connect(audioCtx.destination);
      source.start();
    };
    document.body.addEventListener('click', playAudioLoop);
    document.getElementById('playButton').addEventListener('click', playAudioLoop);
  </script>
</body>
</html>`;

    const sizes = calculateFileSize(htmlContent, originalSize);
    setFileSize(sizes);
    setShowSizeWarning(parseFloat(sizes.compressed) > FILE_SIZE_WARNING_THRESHOLD);
    setGeneratedHTML(htmlContent);
    setPreviewHTML(generatePreviewHTML(htmlContent));
  };

  const handleDownload = () => {
    if (!generatedHTML) return;
    
    const blob = new Blob([generatedHTML], { type: 'text/html' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'sound_magic.html';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleQuantizationBitsChange = (bits) => {
    setQuantizationBits(bits);
    setScaleFactor(Math.pow(2, bits) - 1);
  };

  const copyHTML = async () => {
    try {
      await navigator.clipboard.writeText(generatedHTML);
      alert('HTML copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy HTML:', err);
    }
  };

  const handleReceiverAddressChange = (event) => {
    setOrderDetails({ ...orderDetails, receiverAddress: event.target.value });
  };

  const handleFeeRateChange = (event) => {
    setOrderDetails({ ...orderDetails, feeRate: event.target.value });
  };

  const handleInscribe = async () => {
    if (!generatedHTML) {
        alert('Please generate the HTML first.');
        return;
    }

    try {
        // Create a Blob from the HTML content
        const blob = new Blob([generatedHTML], { type: 'text/html' });
        const formData = new FormData();
        formData.append('file', blob, 'sound_magic.html');

        const response = await fetch(`${BACKEND_URL}/upload`, {
            method: 'POST',
            body: formData,
            headers: {
                'User-Selected-Fee-Rate': orderDetails.feeRate,
                'User-Selected-Receiver-Address': orderDetails.receiverAddress,
            },
        });

        if (response.ok) {
            const result = await response.json();
            setOrderDetails({
                ...orderDetails,
                totalAmount: result.payAddressAmount,
                totalAmountBTC: result.payAddressAmount / 100000000,
                payAddress: result.payAddress,
                receiverAddress: result.receiverAddress,
                devAddress: result.devAddress,
                devFee: result.devFee,
                feeRate: result.feeRate,
            });
            setIsModalOpen(true);
        } else {
            throw new Error('Failed to upload HTML and create order');
        }
    } catch (error) {
        console.error('Error during the fetch request:', error);
        alert('Failed to process inscription. Please try again.');
    }
  };

  const handleConfirm = async () => {
    if (window.unisat && window.unisat.sendBitcoin) {
      try {
        const txidPay = await window.unisat.sendBitcoin(
          orderDetails.payAddress, 
          orderDetails.totalAmount, 
          { feeRate: parseInt(orderDetails.feeRate) }
        );
        alert('Transaction successful! TXID: ' + txidPay);
        setIsModalOpen(false);
      } catch (error) {
        console.error('Transaction failed:', error);
        alert('Transaction failed. Please try again or check your wallet.');
      }
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const processWithFFT = (audioData) => {
    const fftSize = 2048;
    const fft = new FFT(fftSize);
    const hannWindow = new Float64Array(fftSize);
    const processedChunks = [];
    const overlapBuffer = new Float32Array(fftSize).fill(0);
    const hopSize = fftSize / 4;
    
    // Create Hann window
    for (let i = 0; i < fftSize; i++) {
      hannWindow[i] = 0.5 * (1 - Math.cos(2 * Math.PI * i / (fftSize - 1)));
    }

    // Noise profile estimation (first pass)
    const noiseProfile = new Float32Array(fftSize / 2);
    let frameCount = 0;
    
    // First pass - estimate noise floor
    for (let i = 0; i < audioData.length; i += fftSize) {
      const chunk = audioData.slice(i, i + fftSize);
      if (chunk.length === fftSize) {
        const input = new Float64Array(fftSize);
        for (let j = 0; j < fftSize; j++) {
          input[j] = chunk[j] * hannWindow[j];
        }

        const spectrum = fft.createComplexArray();
        fft.realTransform(spectrum, input);

        // Calculate magnitude spectrum
        for (let j = 0; j < fftSize / 2; j++) {
          const real = spectrum[j * 2];
          const imag = spectrum[j * 2 + 1];
          const magnitude = Math.sqrt(real * real + imag * imag);

          // Update noise profile
          noiseProfile[j] += magnitude;
        }

        frameCount++;
      }
    }

    // Average noise profile
    for (let i = 0; i < fftSize / 2; i++) {
      noiseProfile[i] /= frameCount;
    }

    // Second pass - apply noise reduction and processing
    for (let i = 0; i < audioData.length; i += hopSize) {
      const chunk = audioData.slice(i, i + fftSize);
      if (chunk.length === fftSize) {
        const input = new Float64Array(fftSize);
        for (let j = 0; j < fftSize; j++) {
          input[j] = chunk[j] * hannWindow[j];
        }

        const spectrum = fft.createComplexArray();
        fft.realTransform(spectrum, input);

        // Apply noise reduction and processing
        for (let j = 0; j < fftSize / 2; j++) {
          const real = spectrum[j * 2];
          const imag = spectrum[j * 2 + 1];
          const magnitude = Math.sqrt(real * real + imag * imag);

          // Apply noise reduction
          const reducedMagnitude = Math.max(magnitude - noiseProfile[j] * noiseReduction, 0);

          // Apply high-cut filter
          if (j * sampleRate / fftSize > highCutFreq) {
            reducedMagnitude = 0;
          }

          // Apply low-cut filter
          if (j * sampleRate / fftSize < lowCutFreq) {
            reducedMagnitude = 0;
          }

          // Update spectrum with processed magnitude
          const phase = Math.atan2(imag, real);
          spectrum[j * 2] = reducedMagnitude * Math.cos(phase);
          spectrum[j * 2 + 1] = reducedMagnitude * Math.sin(phase);
        }

        // Inverse FFT
        const output = fft.createComplexArray();
        fft.inverseTransform(output, spectrum);

        // Overlap-add and store processed chunk
        for (let j = 0; j < fftSize; j++) {
          overlapBuffer[j] += output[j] * hannWindow[j];
        }

        // Store processed chunk and reset overlap buffer
        if (i + hopSize < audioData.length) {
          processedChunks.push(...overlapBuffer.slice(0, hopSize));
          overlapBuffer.set(overlapBuffer.slice(hopSize));
          overlapBuffer.fill(0, hopSize);
        }
      }
    }

    // Concatenate processed chunks and return
    return new Float32Array([...processedChunks, ...overlapBuffer]);
  };
  return (
    <div className="max-w-7xl mx-auto p-6 bg-gray-900 min-h-screen">
      <div className="text-center mb-12 bg-gray-800 rounded-xl p-8 shadow-lg">
        <h1 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-emerald-400">
          Sound Magic
        </h1>
        <p className="text-gray-400 mt-2">Transform your audio into Ordinal-ready art</p>
      </div>
      
      <div className="bg-gray-800 rounded-xl p-6 shadow-lg mb-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <div className="bg-gray-700 rounded-lg p-4">
            <div className="flex justify-between items-center mb-2">
              <div className="relative group">
                <span className="text-gray-300">Duration</span>
                <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-2 bg-black text-white text-sm rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
                  Longer duration = larger file size and more expensive to inscribe
                </div>
              </div>
              <span className="text-emerald-400">{duration}s</span>
            </div>
            <input
              type="range"
              min="1"
              max="30"
              value={duration}
              onChange={(e) => setDuration(Number(e.target.value))}
              className="w-full h-2 bg-gray-600 rounded-lg appearance-none cursor-pointer"
            />
          </div>
  
          <div className="bg-gray-700 rounded-lg p-4">
            <div className="flex justify-between items-center mb-2">
              <div className="relative group">
                <span className="text-gray-300">Quantization</span>
                <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-2 bg-black text-white text-sm rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
                  Higher bit rate = better quality but larger file size and more expensive to inscribe
                </div>
              </div>
              <span className="text-emerald-400">{quantizationBits} bits</span>
            </div>
            <input
              type="range"
              min="2"
              max="8"
              value={quantizationBits}
              onChange={(e) => handleQuantizationBitsChange(Number(e.target.value))}
              className="w-full h-2 bg-gray-600 rounded-lg appearance-none cursor-pointer"
            />
          </div>
        </div>
  
        <div className="bg-gray-700 rounded-lg p-4 mb-6">
          <label className="flex items-center justify-center gap-4 cursor-pointer hover:bg-gray-600 transition-all p-4 rounded-lg border-2 border-dashed border-gray-600">
            <input 
              type="file" 
              accept="audio/*" 
              onChange={handleAudioUpload}
              className="hidden"
            />
            <span className="text-2xl text-emerald-400">↑</span>
            <span className="text-gray-300">Choose Audio File</span>
          </label>
        </div>
  
        <div className="bg-gray-700 rounded-lg p-4">
          <label className="flex items-center gap-3 text-gray-300">
            <input
              type="checkbox"
              checked={includeBackground}
              onChange={(e) => setIncludeBackground(e.target.checked)}
              className="w-4 h-4 rounded border-gray-600"
            />
            <span>Include Background</span>
          </label>
          {includeBackground && (
            <input
              type="text"
              placeholder="Enter Inscription ID"
              value={inscriptionId}
              onChange={(e) => setInscriptionId(e.target.value)}
              className="mt-4 w-full bg-gray-600 rounded-lg p-2 text-gray-200 placeholder-gray-400"
            />
          )}
        </div>
      </div>
  
      {encodedData && (
        <div className="bg-gray-800 rounded-xl p-6 shadow-lg">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-xl font-semibold text-gray-200">Preview</h3>
            <div className="text-sm text-gray-400">
              <span>Original: {fileSize.original} KB → {fileSize.compressed} KB </span>
              <span className="ml-2 text-emerald-400">({fileSize.compressionRatio}% compressed)</span>
            </div>
          </div>
  
          <div className="mb-6">
            <iframe
              srcDoc={previewHTML}
              title="Sound Magic Preview"
              className="w-full h-96 bg-gray-700 rounded-lg"
              sandbox="allow-scripts allow-same-origin"
            />
          </div>
  
          <div className="mt-8 space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-gray-400 mb-2">Receiver Address</label>
                <input 
                  type="text" 
                  value={orderDetails.receiverAddress} 
                  onChange={handleReceiverAddressChange} 
                  placeholder="Enter receiver address"
                  className="w-full bg-gray-700 rounded-lg p-3 text-gray-200"
                />
              </div>
              <div>
                <label className="block text-gray-400 mb-2">Fee Rate (sat/vB)</label>
                <input 
                  type="number" 
                  value={orderDetails.feeRate} 
                  onChange={handleFeeRateChange} 
                  min="1"
                  className="w-full bg-gray-700 rounded-lg p-3 text-gray-200"
                />
              </div>
            </div>
  
            <button 
              onClick={handleInscribe}
              disabled={showSizeWarning}
              className="w-full bg-blue-500 hover:bg-blue-600 text-white px-6 py-3 rounded-lg transition-all disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <span className="mr-2">⚡</span>
              Inscribe
            </button>
          </div>
        </div>
      )}
  
      <ConfirmPurchaseModal
        isOpen={isModalOpen}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        paymentDetails={{
          payAddressAmount: orderDetails.totalAmount,
          payAddress: orderDetails.payAddress,
          receiverAddress: orderDetails.receiverAddress,
          feeRate: orderDetails.feeRate,
        }}
      />
      {errorMessage && (
        <div className="error-message bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-4">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{errorMessage}</span>
        </div>
      )}
    </div>
  );
  
};

export default SoundMagic;